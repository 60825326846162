import makeEpic from "../../../../../epics/makeEpic";
import { openDialog, closeDialog, loadSubscriptionData } from "../../../../App/actionCreators/index";
import * as actionTypes from "../actionTypes";
import webshopAdminDialogId from "../dialogId";
import valueActionType from "./valueActionType";
import { AppConfigSelector } from "../../../../App/epics/appConfig/selectors";
import type { WebshopAdminDialog as WebshopAdminDialogType } from "../../flowTypes";

import { upgradeDialogOpenedAction } from "../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { SHOP_PAGES_ROUTES } from "../../../../../../../src/dashboard/src/components/Pages/OnlineShop/constants";
import { mapShopRouteToUrl } from "../../../../../../../src/dashboard/src/components/Pages/OnlineShop/utils";

import { receiveOnly } from "../../../../../epics/makeCondition";
import { DialogTypes } from "../../constants";
import {
    PRODUCT_WIDGET_ONBOARDING_SUCCESS,
    PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS,
    PRODUCT_WIDGET_SET_ITEM
} from "../../../ProductWidget/actionTypes";
import { SetupStatusValues, SetupMethod } from "../../../ProductWidget/epics/productSetup/index";
import ProductWidgetKind from "../../../ProductWidget/kind";
import { FEATURED_PRODUCTS_UPDATE_TIMESTAMP } from "../../../FeaturedProducts/actionTypes";
import { HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT } from "../../../../Tooltip/actionTypes";
import webshopActionTypes from "../../reducer/actionTypes";
import ProductWidgetProductSetupValueActionType from "../../../ProductWidget/epics/productSetup/valueActionType";
import { WebshopKind } from "../../kind";

const defaultState: WebshopAdminDialogType & { dialogType: string } = {
    iframeRefreshCounter: 0,
    dialogType: ""
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [AppConfigSelector("oneWeb.webshop.upgradePath"), actionTypes.SHOW_WEBSHOP_UPGRADE],
            reducer: ({ state, values: [upgradePath] }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.UPGRADE_SHOP
                    },
                    multipleActionsToDispatch: [
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(upgradePath),
                            dialogType: DialogTypes.UPGRADE_SHOP
                        }),
                        upgradeDialogOpenedAction({
                            feature: WebshopKind,
                            dialogId: webshopAdminDialogId
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.OPEN_SHOP_PRODUCT_FILTERS],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        closeDialog(),
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(SHOP_PAGES_ROUTES.PRODUCT_FILTERS),
                            dialogType: DialogTypes.MANAGE_SHOP
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.SHOW_WEBSHOP_ADMIN_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.MANAGE_SHOP
                    },
                    multipleActionsToDispatch: [
                        closeDialog(),
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(SHOP_PAGES_ROUTES.HOME),
                            dialogType: DialogTypes.MANAGE_SHOP
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_ADMIN_DIALOG],
            reducer: ({ state }) => {
                const timestamp = Date.now();
                const multipleActionsToDispatch = [
                    closeDialog(),
                    {
                        type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP,
                        payload: {
                            timestamp
                        }
                    },
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp
                        }
                    }
                ];
                return {
                    state: {
                        ...state,
                        iframeRefreshCounter: state.iframeRefreshCounter + 1
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_UPGRADE_SUCCESS],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [AppConfigSelector("oneWeb.webshop.onboardingPath"), actionTypes.SHOW_WEBSHOP_ONBOARDING_DIALOG],
            reducer: ({ state, values: [onboardingPath] }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.ONBOARDING
                    },
                    multipleActionsToDispatch: [
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(onboardingPath),
                            dialogType: DialogTypes.ONBOARDING
                        }),
                        { type: HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT }
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.SHOW_WEBSHOP_UPGRADE_SUCCESS],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.UPGRADE_SHOP_SUCCESS
                    },
                    multipleActionsToDispatch: [
                        loadSubscriptionData(),
                        closeDialog(),
                        openDialog(webshopAdminDialogId, {
                            dialogType: DialogTypes.UPGRADE_SHOP_SUCCESS
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.CLOSE_WEBSHOP_ONBOARDING_DIALOG, receiveOnly(ProductWidgetProductSetupValueActionType)],
            reducer: ({ dispatchAsync, state, values: [{ productId, isWebshopSetupDone }, { setupMethod }] }) => {
                let multipleActionsToDispatch: Action[] = [
                    closeDialog(),
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp: Date.now()
                        }
                    }
                ];
                if (isWebshopSetupDone) {
                    multipleActionsToDispatch = [
                        ...multipleActionsToDispatch,
                        {
                            type: PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS
                        }
                    ];
                    setTimeout(() => {
                        dispatchAsync({
                            type: PRODUCT_WIDGET_ONBOARDING_SUCCESS,
                            payload: { setupStatus: SetupStatusValues.COMPLETED }
                        });
                    }, 100);
                }
                if (productId && setupMethod === SetupMethod.PRODUCT_WIDGET) {
                    multipleActionsToDispatch = [
                        ...multipleActionsToDispatch,
                        {
                            type: PRODUCT_WIDGET_SET_ITEM,
                            payload: {
                                componentKind: ProductWidgetKind,
                                isUpdate: true,
                                products: [productId].map(id => `product: '${id}'`).join()
                            }
                        }
                    ];
                }

                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_IFRAME_DIALOGS],
            reducer: ({ state }) => {
                let actionToDispatch = {};
                switch (state.dialogType) {
                    case DialogTypes.MANAGE_SHOP:
                    case DialogTypes.UPGRADE_SHOP:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_ADMIN_DIALOG };
                        break;
                    case DialogTypes.ONBOARDING:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_ONBOARDING_DIALOG };
                        break;
                    case DialogTypes.UPGRADE_SHOP_SUCCESS:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_UPGRADE_SUCCESS };
                        break;
                    default:
                        actionToDispatch = {};
                }
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_UPGRADE],
            reducer: ({ state }) => {
                const multipleActionsToDispatch = [closeDialog()];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_ONBOARDING_DIALOG],
            reducer: ({ state }) => {
                const timestamp = Date.now();
                const multipleActionsToDispatch = [
                    closeDialog(),
                    {
                        type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP,
                        payload: {
                            timestamp
                        }
                    },
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp
                        }
                    }
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        }
    ]
});
