import { SubscriptionType } from "../../../../../../server/shared/accessManager/constants";
import {
    isUnknownSubscription,
    isTrialSubscription,
    isBasicSubscription,
    isTrialOrBasicSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
    isInvalidSubscription,
    isValidSubscription,
} from "../../../../../../server/shared/accessManager/utils";

// These constants should be removed once we have feature manager and these should come from config
// These are exported only for unit tests purpose, do not in code, use functions
const BASIC_USER_MAX_PUBLIC_PAGE = 1,
    BASIC_USER_MAX_SECTION_LINK = 5,
    NORMAL_USER_MAX_PUBLIC_PAGE = 5,
    NORMAL_USER_MAX_SECTION_LINK = 5,
    //This is very big number and is not supposed to be used. This code should be eliminated when using feature management.
    MAX_PAGE_OR_SECTION_LINK_LIMIT = 150000;

const getPremiumSubscription = () => SubscriptionType.PREMIUM,
    getEcommerceSubscription = () => SubscriptionType.ECOMMERCE;

const getPageLimitBySubscriptionType = (subscriptionType: string): number => {
    if (isBasicSubscription(subscriptionType)) {
        return BASIC_USER_MAX_PUBLIC_PAGE;
    }
    if (isNormalSubscription(subscriptionType)) {
        return NORMAL_USER_MAX_PUBLIC_PAGE;
    }
    return MAX_PAGE_OR_SECTION_LINK_LIMIT;
};
const getSectionLinksLimitBySubscriptionType = (subscriptionType: string): number => {
    if (isBasicSubscription(subscriptionType)) {
        return BASIC_USER_MAX_SECTION_LINK;
    }
    if (isNormalSubscription(subscriptionType)) {
        return NORMAL_USER_MAX_SECTION_LINK;
    }
    //This is very big number and is not supposed to be used. This code should be eliminated when using feature management.
    return MAX_PAGE_OR_SECTION_LINK_LIMIT;
};

const isPagesOrSectionLinksLimitExceededGivenSubscriptionType =
(pagesCount: number, sectionLinksCount: number, subscriptionType: string): boolean => {
    if (isBasicSubscription(subscriptionType)) {
        return (
            pagesCount > BASIC_USER_MAX_PUBLIC_PAGE || // 1
                sectionLinksCount > BASIC_USER_MAX_SECTION_LINK // 5
        );
    }
    if (isNormalSubscription(subscriptionType)) {
        return (
            pagesCount > NORMAL_USER_MAX_PUBLIC_PAGE ||
                sectionLinksCount > NORMAL_USER_MAX_SECTION_LINK
        );
    }
    return false;
};

export {
    BASIC_USER_MAX_PUBLIC_PAGE,
    BASIC_USER_MAX_SECTION_LINK,
    NORMAL_USER_MAX_PUBLIC_PAGE,
    NORMAL_USER_MAX_SECTION_LINK,
    MAX_PAGE_OR_SECTION_LINK_LIMIT,
    isUnknownSubscription,
    isTrialSubscription,
    isBasicSubscription,
    isTrialOrBasicSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
    isInvalidSubscription,
    getPremiumSubscription,
    getEcommerceSubscription,
    isValidSubscription,
    getPageLimitBySubscriptionType,
    getSectionLinksLimitBySubscriptionType,
    isPagesOrSectionLinksLimitExceededGivenSubscriptionType,
};
