import { getLayers, getRowsAndColumns } from "../Preview/flattening/Flattening";
import Template from "../oneweb/Template/kind";
import isStretchComponentKind from "../oneweb/isStretchComponentKind";
import Code from "../oneweb/Code/kind";
import Text from "../oneweb/Text/kind";
import Image from "../oneweb/Image/kind";
import type { SequenceMap, Column, RelInFloats } from "./flowTypes";
import type { ComponentsMap, AnyComponent } from "../../redux/modules/children/workspace/flowTypes";
import type { TemplateComponent } from '../oneweb/Template/flowTypes';
import { depthSorter } from "../Preview/flattening/util";
import applyMobileDown from "./applyMobileDown";
import { cmpCopy } from "./constants";
import isMobileHide from "../Preview/View/isMobileHide";
import type { MobileHideMap } from "../Preview/View/isMobileHide";
import groupCmpsToShowInRow from "./groupCmpsToShowInRow";
import { isBoxKind, isSectionKind } from "../oneweb/componentKinds";
import { getAllCmpIdsInDefaultMode } from "../oneweb/HoverBox/utils";
import type { Attachments } from "../Workspace/epics/componentAttachements/flowTypes";

/*
Floating item rules
1. Outside template - top of the page(arranged based on top and left)
2. Floats are arranged based on z-index : lower z-index first(depth to top)
    a. Box and Image
        non relin: above
        rows: inside
        relin: below

    b. Strip
        non relin: above
        relin: Inside
        rows: Inside
*/

const getBlockId = (column) => {
    const { block, component, rows, floating } = column;
    return (
        (block && block.id && block.kind) &&
        (
            isStretchComponentKind(block.kind, block.stretch) ||
            component ||
            rows ||
            (floating && floating.length && block.kind === Template)
        )
    ) ? block.id : null;
};
const isNotEmptyCmp = (id) =>
        id.indexOf(cmpCopy) > -1 || true,
    shouldAdd = (id, seq) => {
        if (isNotEmptyCmp(id)) {
            return true;
        }
        if (seq[id] && seq[id].length) {
            let ids = seq[id].slice();
            for (let i = 0; i < ids.length; i++) {
                if (isNotEmptyCmp(ids[i])) {
                    return true;
                }
                ids.push(...(seq[ids[i]] || []));
            }
        }
        return false;
    };
const removeEmptyBlocks = (seq, componentsMap, hiddenComponents) => {
    let newSeq = {};
    Object.keys(seq).forEach(id => {
        seq[id].forEach(cId => {
            if (shouldAdd(cId, seq)) {
                if (!newSeq[id]) {
                    newSeq[id] = [];
                }
                newSeq[id].push(cId);
            } else {
                hiddenComponents.push(cId);
            }
        });
    });
    return newSeq;
};

export const
    getSequence = (column: Column, relInFloats: RelInFloats, structure: any, mobileHideMap: MobileHideMap, withHiddenCmp: boolean) => { //eslint-disable-line max-len
        let wrappedCmps: any[] = [];
        let hiddenComponents: any[] = [];
        const seq: any[] = [],
            canAddCmp = (cmp: Record<string, any>) => {
                let isParent = false;
                if (isBoxKind(cmp.kind) || cmp.kind === Image) {
                    isParent = !!structure[cmp.id] && !!structure[cmp.id].rows;
                }
                if (isStretchComponentKind(cmp.kind)) {
                    isParent = !!structure[cmp.id];
                }
                if (isMobileHide(cmp, isParent, structure, mobileHideMap)) {
                    mobileHideMap[cmp.id] = true; // eslint-disable-line no-param-reassign
                    return false;
                }
                return true;
            },
            addToSequence = (cmp: Record<string, any>) => {
                // since a hidden section stays in the sequence
                // all components in a hidden section need to be hidden as well
                let isParentSectionHidden = false;
                if (isSectionKind(column.block.kind) && column.block.mobileHide) {
                    isParentSectionHidden = true;
                }

                if (withHiddenCmp || (canAddCmp(cmp) && !isParentSectionHidden)) {
                    seq.push(cmp.id);
                } else {
                    hiddenComponents.push(cmp.id);

                    // sections need to be added to the sequence
                    // so they are not removed from the mobile view completely
                    // this is because section links should still work with hidden sections
                    if (isSectionKind(cmp.kind)) {
                        seq.push(cmp.id);
                    }
                }
            },
            addRelInFloats = (id) => {
                if (id && relInFloats[id]) {
                    relInFloats[id].sort(depthSorter).forEach(float => {
                        addToSequence(float);
                        addRelInFloats(float.id);
                    });
                }
            };
        let
            processCol = (col: Column) => {
                const {
                        floating,
                        wrappedComponents,
                        component,
                        rows,
                        block,
                        modernColumn,
                        components,
                    } = col,
                    { id } = block || {}, addingSequence: any[] = [], floatingMap = {};

                if (block && block.kind === Text && wrappedComponents && wrappedComponents.length) {
                    wrappedCmps = wrappedComponents.filter(cmp => canAddCmp(cmp));
                }
                if (id && floating && floating.length) {
                    relInFloats[id] = [];// eslint-disable-line no-param-reassign
                }
                if (floating) {
                    addingSequence.push(...floating);
                    floating.forEach(float => { floatingMap[float.id] = true; });
                }
                if (component) {
                    addingSequence.push(component);
                }
                if (modernColumn && components && components.length > 1) {
                    addingSequence.push(...components);
                } else if (addingSequence.length) {
                    addingSequence.sort(depthSorter);
                    const lastComponent = addingSequence.shift();
                    addingSequence.push(lastComponent);
                }
                addingSequence.forEach(component => {
                    if (floatingMap[component.id]) {
                        if (id && relInFloats[id]) {
                            if (block && (isStretchComponentKind(block.kind, block.stretch) || block.kind === Template)) {
                                addToSequence(component);
                                addRelInFloats(component.id);
                            } else {
                                relInFloats[id].push(component);
                            }
                        } else {
                            addToSequence(component);
                            addRelInFloats(component.id);
                        }
                    } else {
                        addToSequence(component);
                        if (!isStretchComponentKind(component.kind, component.stretch)) {
                            addRelInFloats(component.id);
                        }
                    }
                });
                (rows || []).forEach(row => {
                    processRow(row);// eslint-disable-line
                });
            },
            processRow = (row) => {
                const { floating, columns } = row;
                if (floating) {
                    floating.forEach(float => {
                        addToSequence(float);
                    });
                }
                columns.forEach(col => {
                    processCol(col);
                });
            };

        processCol(column);
        return {
            blockId: getBlockId(column),
            data: seq,
            hiddenComponents,
            wrappedCmps
        };
    },
    getSequenceData = (componentsMap: ComponentsMap,
        template: TemplateComponent,
        attachments: Attachments,
        flatteningData: Record<string, any> = {}, withHiddenCmp: boolean = false): SequenceMap => {
        const
            structure = flatteningData,
            newComponentsMap = {},
            wrappedCmpsMap = {};
        let cmps: Array<AnyComponent> = [];
        const ignoreComponents = getAllCmpIdsInDefaultMode(componentsMap, attachments);
        Object.keys(componentsMap).forEach(id => {
            // $FlowFixMe: TODO: fix
            const newCmp: AnyComponent = { ...componentsMap[id] };
            if (newCmp.kind !== Code || !newCmp.location) {
                newComponentsMap[id] = newCmp;
                cmps.push(newCmp);
            }
        });

        const layers = getLayers(cmps, newComponentsMap, template),
            templateWidth = template.width;
        let data = {};

        let relInFloats = {}, mobileHideMap = {}, result, hiddenComponents: any[] = [];
        layers.reverse().forEach(groups => {
            groups.forEach(group => {
                const { block, block: { id: blockId, kind: blockKind, top: blockTop, left: blockLeft, stretch = false }, items } = group;
                if (!structure[blockId]) {
                    // we assume its current workspace page, if not we need not adjust top/left here
                    const
                        isNonStripBlock = !isStretchComponentKind(blockKind, stretch),
                        isNonTemplateBlock = blockKind !== Template;
                    if (isNonTemplateBlock) {
                        group.items.forEach(c => {
                            c.top = c.top - blockTop; // eslint-disable-line
                            if (isNonStripBlock) {
                                c.left = c.left - blockLeft; // eslint-disable-line
                            }
                        });
                    }

                    structure[blockId] = getRowsAndColumns(items, block, 0, 0, templateWidth, componentsMap);
                }

                result = getSequence(structure[blockId], relInFloats, structure, mobileHideMap, withHiddenCmp);
                if (result.blockId) {
                    data[result.blockId] = result.data && result.data.filter(cmpId => !ignoreComponents.includes(cmpId));
                }
                if (blockKind === Text) {
                    wrappedCmpsMap[blockId] = result.wrappedCmps.map(c => c.id);
                }
                hiddenComponents = [...hiddenComponents, ...result.hiddenComponents];
            });
        });
        data = removeEmptyBlocks(data, componentsMap, hiddenComponents);
        let mobileData = applyMobileDown(data, template.id, componentsMap, wrappedCmpsMap);
        data = removeEmptyBlocks(mobileData.data, componentsMap, hiddenComponents);
        return {
            ...mobileData,
            // @ts-ignore
            data,
            hiddenComponents,
            ...groupCmpsToShowInRow(data, componentsMap, structure, template.id)
        };
    };

export default getSequenceData;
