import { getDAL } from "../../../../dal";
import AppConfig from "../../../utils/AppConfig";
import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import * as colorMapper from '../../../mappers/color';
import { DEFAULT_BUTTON_TEXT } from "./constants";
import { BUTTON_THEME_PRIMARY } from "../../ThemeGlobalData/constants";

const appConfig = AppConfig(getAppConfig());
const appUrl = appConfig.get("oneWeb.apps.bookings.appUrl");

const getBookingsScriptUrl = (isServerPreview = false, locale = '') => {
    const domain = getDAL().getDomain();
    let url = `${appUrl}/api/v1/published/init.js?domain=${domain}`;
    if (!isServerPreview) {
        url += locale ? `&locale=${locale}` : '';
        url += '&preview=true';
    }
    return url;
}

const getBookingParams = ({ component, selectedParentTheme, stylesheetsIdToNameMap }) => {
    return JSON.stringify({
        embedType: "websitebuilder",
        textStyles: {
            titleColor: component?.titleFontColor ? colorMapper.toHex(component.titleFontColor) : null,
            paragraphColor: component?.fontColor ? colorMapper.toHex(component.fontColor) : null
        },
        textTheme: {
            titleClass: component?.titleFontColorTheme,
            paragraphClass: component?.fontColorTheme
        },
        buttonStyles: {
            buttonClass: component?.buttonStyleId ? stylesheetsIdToNameMap[component.buttonStyleId] : null
        },
        buttonTheme: {
            buttonClass: component?.buttonThemeSelected || BUTTON_THEME_PRIMARY
        },
        textFontFamily: component?.fontFamily,
        buttonText: component?.buttonText || DEFAULT_BUTTON_TEXT,
        parentTheme: selectedParentTheme
    });
}

/**
 * Returns the bookings url from config and adds the required params with the token
 * as well as a path if given
 */
const getBookingsUrlWithToken = (token: string, path?: string) => {
    const url = new URL(appUrl);
    url.searchParams.append("bs-req-app", "wsbEditor");
    url.searchParams.append("bs-shrt-tkn", token);

    if (path) {
        url.pathname = path;
    }

    return url.toString();
};

const getBookingsIframeToken = () => {
    return getDAL().getAppViewToken('bookings');
};

export {
    getBookingsScriptUrl,
    getBookingsIframeToken,
    getBookingsUrlWithToken,
    getBookingParams
}
