/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

import * as ids from './ids';
import { MveHeaderToolTipDefaults, PointerDirections } from "./constants";
import {
    MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW,
    MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE,
} from "./actionTypes";
import {
    TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER,
    TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT,
    TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH,
} from '../oneweb/TextLike/constants';

import { REPLACE_DIALOG } from "../../redux/modules/actionTypes";
import { SiteSettingsDialogId, SiteSettingsTabName } from "../SiteSettings/SiteSettingsDialog/constants";
import { isPartnerCheckDomain } from '../ComponentTierManager/utils';
import { BookingsTooltipView } from '../oneweb/Bookings/expandedPanel/whatsNewTooltip';
import { TooltipViewsType } from './flowTypes';
import { BookingsPublishTooltipView } from '../oneweb/Bookings/expandedPanel/publishBtnTooltip';

const calculatePointerEdge = (pointerHeight) => {
    // pythagoras below; to calculate a square's edge; from HALF-OF-DIGONAL
    return Math.sqrt((Math.pow(pointerHeight * 2, 2)) / 2);
};

const componentNotReadyMsg =
    "msg: tooltip.componentDisabled {We\'re not ready with the {componentKind} component yet, but it won\'t be long.}",
    dontShowThisAgain = "msg: common.dontShowAgain {Don't show this again}";
export default {
    [ids.ShiftbarClick]: {
        text: 'msg: tooltip.shiftbarClick {This button is special. Use it to drag the component up or down together with the content beneath.}'
    },
    [ids.HostnetWebShopDisabled]: {
        text: 'msg: tooltip.hostnetWebshopDisabled {The Online shop features are not available yet. As soon as you\'ve finished your new site and have migrated to Website Builder through My Hostnet, you will have access to the Online shop features.}'
    },
    [ids.WebShopDisabled]: {
        text: 'msg: tooltip.webshopOnePerSite {You can only have one Online Shop on your website. Looks like you already have one.}'
    },
    [ids.ContactFormDisabled]: {
        text: 'msg: tooltip.contactFormOnePerPage {You can only have one contact form on a page. Looks like you already have one.}'
    },
    [ids.InstagramForceDisabled]: {
        text: "msg: instagram.accountDisableMsg.instagramFeed {We’re sorry, but this feature is currently not available. We're working on resolving our connection with Instagram. In the meantime, delete this component in order to publish your website. Please come back later and try again. We apologise for the inconvenience.}"
    },
    [ids.FacebookChatForceDisabled]: {
        text: "msg: facebook.accountDisableMsg.fbChat {We’re sorry, but this feature is currently not available. We're working on resolving our connection with Facebook. Please come back later and try again. We apologise for the inconvenience.}"
    },
    [ids.FacebookFeedForceDisabled]: {
        text: "msg: facebook.accountDisableMsg.fbFeed {We’re sorry, but this feature is currently not available. We're working on resolving our connection with Facebook. In the meantime, delete this component in order to publish your website. Please come back later and try again. We apologise for the inconvenience.}"
    },
    [ids.GoogleReviewsForceDisabled]: {
        text: "msg: googleReviews.accountDisableMsg.reviews {The Google reviews component is not available in the trial version.}"
    },
    [ids.GenericFeatureDisabled]: {
        text: "msg: genericFeatureDisabled {This feature is not available for use.}"
    },
    [ids.FacebookChatDisabled]: {
        props: { gap: 0, width: 248 },
        text: 'msg: tooltip.facebookChatOnlyOneOnSite {You have already enabled chat on your site. To edit or disable it go to}',
        linkText: 'msg: tooltip.websiteSettings {website settings.}',
        linkCustomActionDispatch: {
            actionType: REPLACE_DIALOG,
            actionPayload: {
                dialogId: SiteSettingsDialogId,
                props: { activeTabKey: SiteSettingsTabName.MESSENGER_CHAT }
            },
            addLinkSpace: true,
            closeTipOnLinkClick: true
        }
    },
    [ids.CodeDisabled]: {
        text: componentNotReadyMsg,
        params: { componentKind: 'msg: component.code.label {Code}' }
    },
    [ids.DocumentDisabled]: {
        text: componentNotReadyMsg,
        params: { componentKind: 'msg: component.document.label {Document}' }
    },
    [ids.TableDisabled]: {
        text: componentNotReadyMsg,
        params: { componentKind: 'msg: component.table.label {Table}' }
    },
    [ids.FindTips]: {
        text: 'msg: tips.findTips.text {Find the tips up here if you need them}',
        pointerDirection: PointerDirections.Top,
        props: { width: 160, height: 63, gap: 20 }
    },
    [ids.FindVideoTipOnSkip]: {
        text: 'msg: topBarTutorialTooltip.msg {Watch our video and learn step-by-step how to use Website Builder. Edit your website while watching.}',
        pointerDirection: PointerDirections.Top,
        props: { width: 230, gap: 20 }
    },
    [ids.FindVideoTip]: {
        text: 'msg: tutorialHelp.tooltip {You can find our video tutorial here.}',
        pointerDirection: PointerDirections.Top,
        props: { height: 63, gap: 20 }
    },
    [ids.ComponentResize]: {
        pointerDirection: PointerDirections.Top,
        props: { width: 180, height: 63, gap: 20, pointerEdge: 10 }
    },
    [ids.SectionResize]: {
        pointerDirection: PointerDirections.Bottom,
        props: { width: 250, height: 70, gap: 20, pointerEdge: 12 }
    },
    [ids.MobileDownDisabled]: {
        pointerDirection: PointerDirections.Top,
        props: { width: 180, gap: 0 }
    },
    [ids.MobileEditorMenu]: {
        text: 'msg: tooltip.mobileEditorMenu.text {If you want to switch pages, do that from the top bar, or go to \"Preview\" to view how the menu works.}',
        text1: 'msg: tooltip.mobileEditorMenu.text1 {You can edit the names of your pages displayed in the mobile menu, but not how the menu looks.}',
        linkText: 'msg: tooltip.mobileEditorMenu.linkText {Learn how to edit page names}',
        pointerDirection: PointerDirections.Left,
        linkUrl: 'msg: tooltip.mobileEditorMenu.linkUrl {https://help.one.com/hc/en-us/articles/360000348297}',
        props: { gap: 0, pointerEdge: 15, width: MveHeaderToolTipDefaults.width }
    },
    [ids.MobileEditorPageName]: {
        text: 'msg: tooltip.MobileEditorPageName.text {Looking for a way to edit the name of this page? Locate the page in the menu in the top bar, and open page options from there. Edit the name and save.}',
        linkText: 'msg: tooltip.MobileEditorPageName.linkText {Learn more}',
        pointerDirection: PointerDirections.Right,
        linkUrl: 'msg: tooltip.MobileEditorPageName.linkUrl {https://help.one.com/hc/en-us/articles/360000348297}',
        props: { gap: 0, pointerEdge: 15, width: MveHeaderToolTipDefaults.width }
    },
    [ids.MobileEditorReorderTip]: {
        text: 'msg: tooltip.MobileEditorReorderTip.text {Do you want to move this? Try to select the section around it instead, by clicking outside the red border.}',
        chkBoxTxt: dontShowThisAgain,
        chkBoxAction: MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW,
        props: { gap: 0, pointerEdge: 15, width: MveHeaderToolTipDefaults.width }
    },
    [ids.MobileEditorGroupTip]: {
        text: 'msg: tooltip.MobileEditorGroupTip.text {We grouped these elements for you to make them look nice in mobile. Don\'t want them to be grouped? Just go to the desktop view and move them further apart.}',
        chkBoxTxt: dontShowThisAgain,
        chkBoxAction: MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW,
        props: { gap: 0, pointerEdge: 15, width: 289 },
        pointerDirection: PointerDirections.Right
    },
    [ids.BrowserZoomWarn]: {
        header: 'msg: tooltip.BrowserZoom.header {Reset zoom}',
        subHeader: 'msg: tooltip.BrowserZoom.subheader {Return to actual size}'
    },
    [ids.SectionLinkPageDeleted]: {
        props: { gap: 5, pointerEdge: 15, width: 333 },
    },
    [ids.SectionLinkOutdated]: {
        props: { gap: 5, pointerEdge: 15, width: 333 },
    },
    [ids.StripTitleErrorTip]: {
        pointerDirection: PointerDirections.Top,
        props: { gap: 0, pointerEdge: 10 },
        dynamicWidth: true,
    },
    [ids.PhoneTip]: {
        text: 'msg: tooltip.PhoneTip.text {To edit this info click on \"Change phone number\" or go to the section \"General\" in settings via the menu in the upper right corner.}',
        chkBoxTxt: dontShowThisAgain,
        chkBoxAction: NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE,
        props: {
            gap: TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER,
            pointerEdge: calculatePointerEdge(TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT),
            width: TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH,
        },
        pointerDirection: PointerDirections.Right
    },
    [ids.EmailTip]: {
        text: 'msg: tooltip.EmailTip.text {To edit this info click on \"Change email\" or go to the section \"General\" in settings via the menu in the upper right corner.}',
        chkBoxTxt: dontShowThisAgain,
        chkBoxAction: NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL,
        props: {
            gap: TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER,
            pointerEdge: calculatePointerEdge(TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT),
            width: TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH,
        },
        pointerDirection: PointerDirections.Right
    },
    [ids.AddressTip]: {
        text: 'msg: tooltip.AddressTip.text {To edit this info click on \"Change address\" or go to the section \"General\" in settings via the menu in the upper right corner.}',
        chkBoxTxt: dontShowThisAgain,
        chkBoxAction: NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS,
        props: {
            gap: TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER,
            pointerEdge: calculatePointerEdge(TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT),
            width: TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH,
        },
        pointerDirection: PointerDirections.Right
    },
    [ids.ExtendedPanelTooltip]: {
        props: { gap: 2, pointerEdge: 15, width: 250 }
    },
    [ids.ReplaceButtonTooltip]: {
        props: { gap: 2, pointerEdge: 15, width: 270 }
    },
    [ids.HeaderSectionMCTATooltip]: {
        props: { gap: 0, pointerEdge: 13, width: 273 },
        pointerDirection: PointerDirections.Top,
        header: 'msg: tooltip.headerSectionMCTA.header {This is your header}',
        subHeader: 'msg: tooltip.headerFooterSectionMCTA.subheader {It shows on all pages}',
        text2: 'msg: tooltip.headerSectionMCTA.text {A header often contains elements that you want to show at the top of all pages - such as your site menu and company logo.}',
    },
    [ids.FooterSectionMCTATooltip]: {
        props: { gap: 0, pointerEdge: 13, width: 273 },
        pointerDirection: PointerDirections.Bottom,
        header: 'msg: tooltip.footerSectionMCTA.header {This is your footer}',
        subHeader: 'msg: tooltip.headerFooterSectionMCTA.subheader {It shows on all pages}',
        text2: 'msg: tooltip.footerSectionMCTA.text {A footer usually contains elements that you want to show at the bottom of all pages - such as contact information and social icons.}',
    },
    [ids.HeaderSectionTooltip]: {
        props: { gap: 7, pointerEdge: 13, width: 288 },
        pointerDirection: PointerDirections.Top,
        header: 'msg: tooltip.headerSection.header {Header components}',
        subHeader: 'msg: tooltip.headerFooterSection.subheader {Showing on all pages}',
        text2: 'msg: tooltip.headerSection.text {Components you add to the header will show on all pages.}',
        text3: 'msg: tooltip.headerSection.text1 {To show a header component only on one page check the \"Show only on this page\" option in the component\'s toolbar.}',
        actiontext: 'msg: tooltip.headerFooterSection.actionText  {Ok, I got it!}'
    },
    [ids.FooterSectionTooltip]: {
        props: { gap: 7, pointerEdge: 13, width: 288 },
        pointerDirection: PointerDirections.Bottom,
        header: 'msg: tooltip.footerSection.header {Footer components}',
        subHeader: 'msg: tooltip.headerFooterSection.subheader {Showing on all pages}',
        text2: 'msg: tooltip.footerSection.text {Components you add to the footer will show on all pages.}',
        text3: 'msg: tooltip.footerSection.text1 {To show a footer component only on one page check the \"Show only on this page\" option in the component\'s toolbar.}',
        actiontext: 'msg: tooltip.headerFooterSection.actionText  {Ok, I got it!}'
    },
    [ids.SectionShortcutInfoTooltip]: {
        props: { gap: 75, pointerEdge: 15, width: 225 },
        pointerDirection: PointerDirections.Left,
    },
    [ids.ModernLayoutInfoTooltip]: {
        props: { gap: 75, pointerEdge: 15, width: 275 },
        pointerDirection: PointerDirections.Left,
    },
    [ids.SectionFirstIntroToaster]: {
        props: { gap: 0, pointerEdge: 0, width: 416 },
        header: 'msg: tooltip.sectionIntroToaster.header {Hey there!}',
        text2: 'msg: tooltip.sectionIntroToaster.text {We grouped and placed your content into Sections, so you are all set to start using them to build your site.}',
        text3: 'msg: tooltip.sectionIntroToaster.text1 {Check your pages on desktop and mobile view to make sure they look the way you want them.}',
        actiontext: 'msg: common.gotIt.exclamation {Got it!}'
    },
    [ids.AIWritingAssistantTooltip]: {
        props: { gap: 0, pointerEdge: 19, width: 500, height: 312 },
        pointerDirection: PointerDirections.Bottom,
        header: 'msg: tooltip.aiWritingAssistant.header {Try our Writing Assistant}',
        text: 'msg: tooltip.aiWritingAssistant.text.new {Whether you need a new text or want to modify an existing one, our Writing Assistant is here to help. Click \"Help me write\" and try it out now!}',
        actiontext: 'msg: tooltip.aiWritingAssistant.actionText {Don’t show again}'
    },
    [ids.InstagramGalleryDisabled]: {
        header: 'msg: component.instagramGallery.premiumFeature {{premiumPackageName} feature}',
    },
    [ids.FacebookFeedGalleryDisabled]: {
        header: 'msg: component.instagramGallery.premiumFeature {{premiumPackageName} feature}',
    },
    [ids.SingleProductComponentTip]: {
        props: { gap: 17, pointerEdge: 15, width: 447 }
    },
    [ids.addYourShopToolTipSeen]: {
        props: { gap: 0, pointerEdge: 15, width: 600 },
    },
    [ids.SpellcheckTooltip]: {
        header: 'msg: component.text.browserSpellcheck {Browser spellcheck}',
        text: 'msg: component.text.spellcheck.help {Manage spellcheck activation, deactivation and language preferences in your browser settings.}',
        inlineLink: true,
        linkText: !isPartnerCheckDomain() ? 'msg: common.learnMore {Learn more}' : '',
        pointerDirection: PointerDirections.Bottom,
        linkUrl: !isPartnerCheckDomain() ? 'msg: spellCheck.learnMore {https://help.one.com/hc/en-us/articles/22911562019601-How-to-manage-spell-check-in-Website-Builder}' : '',
        props: { gap: 0, pointerEdge: 15, width: MveHeaderToolTipDefaults.width },
        actiontext: 'msg: common.gotIt {Got it}'
    },
    [ids.NewComponentBookingsToolip]: {
        props: { gap: -5, pointerEdge: 15, width: 382, height: 312 },
        pointerDirection: PointerDirections.Left
    },
    [ids.BookingsPublishButtonTooltip]: {
        props: { gap: 5, pointerEdge: 18, width: 320 },
        pointerDirection: PointerDirections.Top
    },
};

export const TooltipViews: TooltipViewsType = {
    [ids.NewComponentBookingsToolip]: BookingsTooltipView,
    [ids.BookingsPublishButtonTooltip]: BookingsPublishTooltipView
}
