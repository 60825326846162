import React from 'react';
import cx from "classnames";
import styles from './webShopPolicies.css';
import { getTextStyles } from '../../WebShopPaymentMethods/utils';
import { createSelectedParentThemeWithThemeTextClass } from "../../../../ThemeGlobalData/utils/commonUtils";
import { toHex } from "../../../../../mappers/color";

const View = ({
    component: { privacyPolicyText },
    specialFooterData: {
        privacyPolicyContent,
        data: { policies }
    },
    stylesheets,
    selectedParentTheme,
    generic
}) => {
    let textStyle = getTextStyles(stylesheets),
        themeOverrideColor = generic && generic.themeOverrideColor,
        selectedColor = generic && generic.textStyle && generic.textStyle.color;
    selectedColor = selectedColor && toHex(selectedColor);
    textStyle = { ...textStyle, color: selectedColor || textStyle.color };

    return (
        <React.Fragment>
            <div
                className={cx(
                    styles.container,
                    createSelectedParentThemeWithThemeTextClass({ selectedParentTheme }),
                    themeOverrideColor
                )}
            >
                <div className="wsbPolicyLinksContainer">
                    { privacyPolicyContent &&
                        <React.Fragment>
                            <a key="privacyPolicy" className={cx("privacyPolicyLink", "openPrivacyPolicyDialog")}>
                                <span style={textStyle}>{privacyPolicyText}</span>
                            </a>
                            { policies.length > 0 &&
                                <span className="wsbPolicyLinkSeparator" style={{ color: textStyle.color }}>|</span>}
                        </React.Fragment>}
                </div>
                <div className="webShopPolicyLinksContainer">
                    {(policies && policies.length) ? policies.map((policy, i) => (
                        <React.Fragment>
                            <a key={i} className="webShopPolicyLink">
                                <span style={textStyle}>{policy.name}</span>
                            </a>
                            {i < policies.length - 1 &&
                                <span className="webShopPolicyLinkSeparator" style={{ color: textStyle.color }}>|
                                </span>}
                        </React.Fragment>
                    )) : null}
                </div>
            </div>
            <a style={{ display: "none" }} className="hidden-webShopPolicyLink">
                <span style={textStyle} />
            </a>
            <span className="hidden-webShopPolicyLinkSeparator" style={{ color: textStyle.color, display: "none" }}>|
            </span>
        </React.Fragment>);
};

export default View;
