export const
    BOOKINGS_FONT_FAMILY_CHANGED = "BOOKINGS_FONT_FAMILY_CHANGED",
    BOOKINGS_COLOR_CHANGED = "BOOKINGS_COLOR_CHANGED",
    BOOKINGS_COLOR_CHANGED_AUTO_COLOR = "BOOKINGS_COLOR_CHANGED_AUTO_COLOR",
    BOOKINGS_TITLE_COLOR_CHANGED = "BOOKINGS_TITLE_COLOR_CHANGED",
    BOOKINGS_TITLE_COLOR_CHANGED_AUTO_COLOR = "BOOKINGS_TITLE_COLOR_CHANGED_AUTO_COLOR",
    BOOKINGS_BUTTON_THEME_CHANGED = "BOOKINGS_BUTTON_THEME_CHANGED",
    BOOKINGS_BUTTON_STYLE_CHANGED = "BOOKINGS_BUTTON_STYLE_CHANGED",
    BOOKINGS_BUTTON_TEXT_CHANGED = "BOOKINGS_BUTTON_TEXT_CHANGED",
    BOOKINGS_CLOSE_NOTE = "BOOKINGS_CLOSE_NOTE";
