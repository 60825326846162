import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import ContactKind from "../../oneweb/ContactForm/kind";
import { ADDRESS_KIND as AddressKind } from "../../oneweb/TextLike/Address/kind";
import { EMAIL_KIND as EmailKind } from "../../oneweb/TextLike/Email/kind";
import { PHONE_KIND as PhoneKind } from "../../oneweb/TextLike/Phone/kind";
import { OPENING_HOURS_KIND } from "../../oneweb/OpeningHours/kind";
import FACEBOOK_CHAT_KIND from "../../oneweb/FacebookChat/kind";
import * as Icon from './Icons.css';
import { Contact, Address, Email, Phone, OpeningHours } from "./constants";
import * as styles from '../view/Inserter.css';
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "../view/EPSeparator";
import { ComponentNames } from "../../oneweb/constants";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../view/DroppableCmpShortcut";
import type { ExtendedPanelContentProps } from "../view/inserter";
import disabledCmpTooltipHandlers from "../view/disabledCmpTooltipHandlers";
import type { DisabledShortcutProps, DisabledShortcutPropsExt } from "../flowTypes";
import { OpenSiteSettings } from "../view/EditExtendedPanelContent";
import { KindToInputId } from "../../oneweb/TextLike/kindToInputId";
import sectionsBlocksVAT from "../epics/sectionsBlocks/valueActionType";
import { stylesheetAppSel } from "../../Workspace/epics/stylesheets/selectors";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import componentsEvalValueActionType from "../../Workspace/epics/componentsEval/valueActionType";
import { globalVariablesEpic } from '../../App/epics/globalVariablesEpic';
import Blocks, { BlocksByColumn } from "./Blocks";
import { injectIntl } from "../../../view/intl/index";
import {
    EXTENDED_PANEL_AVAILABLE_WIDTH_BY_70_PER
} from "../constants";
import HelpTip from "../view/HelpTip";
import useDelayHook from "../useDelayHook";
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';

import SectionBlockError from "../view/SectionBLockError/index";
import SectionBlockErrorStyles from "../view/SectionBLockError/styles.css";
import SectionBlockErrorMessage from "../view/SectionBLockError/SectionBlockErrorMessage";
import handleChatAlreadyAdded from "../../oneweb/FacebookChat/ToolTip/handleChatAlreadyAdded";
import { checkSubscriptionCompatibilityFromKind, isWsbFeatureEnabled } from "../../ComponentTierManager/utils";
import { isTrialSubscription } from "../../App/epics/subscriptionData/utils";
import { WsbFeature } from "../../../../../server/shared/WsbFeature";
import disbaleFBComponents from "../../../utils/disableFBComponents";

const CONTACT_BLOCK = {
    id: 'contact',
    title: "msg: common.blocks.contactBlocks {Contact blocks}"
};

//Later move to resources so template team can change this
const CONTACT_NORMAL_VIEW = ["contact-11", "contact-4", "contact-2", "contact-1", "contact-9", "contact-8"];
const COLUMN_VIEW = { left: ["contact-13"], right: ["contact-10"] };
const ODD_ROW = { left: ["contact-6", "contact-7"], right: ["contact-5"] };

type BlockMap = { [a: string]: Record<string, any> };
const getHeightScale = ({ rect, block }: {
    rect: Record<string, any>,
    parentId?: string,
    block: Record<string, any>
}) => {
    const width = EXTENDED_PANEL_AVAILABLE_WIDTH_BY_70_PER;
    let scale = ODD_ROW.right[0] === block.key ? 0.23 : width / rect.width;
    return {
        scale,
        height: scale * rect.height,
        marginLeft: 0
    };
};

const tipMsg = 'msg: extendedPanel.contactTip {These blocks help you add contact details quickly while ensuring a beautiful layout. The blocks adjust to your template styles once you add them to the workspace.}'; // eslint-disable-line

const ContactFormShortcut = ({ disabled, dispatch }: DisabledShortcutProps) => <ExtendedPanelShortCut
    iconClassName={cx(Icon.contactLarge, { [Icon.disabled]: !!disabled })}
    text={ComponentNames[ContactKind]}
    textClassName={cx({ [Icon.textDisabled]: !!disabled })}
    mouseEventHandlers={disabled ? disabledCmpTooltipHandlers({ componentKind: ContactKind, dispatch }) : {}}
/>;

const FacebookChatShortcut = ({ disabled, forceDisabled, dispatch, subscriptionType }: DisabledShortcutPropsExt) => {
    const
        isUserBNECompatible = checkSubscriptionCompatibilityFromKind(
            FACEBOOK_CHAT_KIND, subscriptionType
        ),
        showPremiumIcon = !isUserBNECompatible || isTrialSubscription(subscriptionType);

    let mouseHoverHandler = {};
    // Check forceDisabled firstly
    if (forceDisabled) {
        mouseHoverHandler = handleChatAlreadyAdded({ dispatch, forceDisabled });
    } else {
        mouseHoverHandler = disabled ? handleChatAlreadyAdded({ dispatch }) : {};
    }
    return (
        <ExtendedPanelShortCut
            iconClassName={cx(Icon.facebookChat, { [Icon.disabled]: !!disabled })}
            text={ComponentNames[FACEBOOK_CHAT_KIND]}
            textClassName={cx({ [Icon.textDisabled]: !!disabled })}
            mouseEventHandlers={mouseHoverHandler}
            showPremiumIcon={showPremiumIcon}
        />
    );
};

type Props = { ContactBlocksView: any } & ExtendedPanelContentProps;
const Content = (props: Props) => {
    const canRender = useDelayHook(200);
    const { componentsKindsToDisableMap, dispatch, intl, ContactBlocksView } = props,
        isContactFormDisabled = componentsKindsToDisableMap.hasOwnProperty(ContactKind),
        isFacebookChatDisabled = componentsKindsToDisableMap.hasOwnProperty(FACEBOOK_CHAT_KIND),
        editContactInfoText = 'msg: extendedPanel.editContactInfo {Edit contact info}';

    if (!canRender) {
        //Make Animation smooth: Time for panel opening animation if 0.2s so that by the time animation completes it will render
        return <LoadingIndicator className={styles.loadingIndicator} />;
    }

    const renderMessengerChatWidgetInserter = () => {
        if (disbaleFBComponents()) {
            return (
                <FacebookChatShortcut disabled forceDisabled dispatch={dispatch} subscriptionType={props.subscriptionType} />
            );
        }
        return (
            isFacebookChatDisabled ?
                <FacebookChatShortcut disabled dispatch={dispatch} subscriptionType={props.subscriptionType} /> :
                <DroppableCmpShortcut {...props} kind={FACEBOOK_CHAT_KIND}>
                    <FacebookChatShortcut dispatch={dispatch} subscriptionType={props.subscriptionType} />
                </DroppableCmpShortcut>
        );
    };

    return (
        <div className={styles.panelWithFilter}>
            <VerticalSpacer y={24} />
            <OpenSiteSettings text={editContactInfoText} intl={intl} dispatch={dispatch} fieldFocus={KindToInputId[AddressKind]} />
            <VerticalSpacer y={24} />
            <div className={styles.shortCutsContainer}>
                <DroppableCmpShortcut {...props} kind={PhoneKind}>
                    <ExtendedPanelShortCut iconClassName={Icon.phone} text={Phone} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={EmailKind}>
                    <ExtendedPanelShortCut iconClassName={Icon.email} text={Email} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={AddressKind}>
                    <ExtendedPanelShortCut iconClassName={Icon.address} text={Address} />
                </DroppableCmpShortcut>
                {isContactFormDisabled ?
                    <ContactFormShortcut disabled dispatch={dispatch} /> :
                    <DroppableCmpShortcut {...props} kind={ContactKind}>
                        <ContactFormShortcut dispatch={dispatch} />
                    </DroppableCmpShortcut>}
                <DroppableCmpShortcut {...props} kind={OPENING_HOURS_KIND}>
                    <ExtendedPanelShortCut iconClassName={Icon.openingHours} text={OpeningHours} />
                </DroppableCmpShortcut>
                {
                    !isTrialSubscription(props.subscriptionType) &&
                    isWsbFeatureEnabled(WsbFeature.FacebookChat) &&
                    renderMessengerChatWidgetInserter()
                }
            </div>
            <VerticalSpacer y={20} />
            <EPSeparator />
            <ContactBlocksView />
            {
                // <SectionsBlocks {...props} kind={StripKind} filterKey={CONTACT_SECTION_KEY} />
            }
        </div>
    );
};

const ContactBlocks = injectIntl(({
    styleSheets,
    componentsEval,
    globalVariables,
    blocks,
    intl,
    dispatch
}) => {
    if (!blocks) {
        return (
            <div>
                <VerticalSpacer y={27} />
                <SectionBlockError
                    containerClass={SectionBlockErrorStyles.textSectionsErrorContainer}
                    iconClass={SectionBlockErrorStyles.textSectionsErrorIconPlaceholder}
                >
                    <SectionBlockErrorMessage intl={intl} dispatch={dispatch} />
                </SectionBlockError>
            </div>
        );
    }
    const
        filteredCategory = blocks.find(categoryObj => categoryObj.key === CONTACT_BLOCK.id),
        blocksMap: BlockMap = (filteredCategory ? filteredCategory.items : []).reduce((acc, block) => ({
            ...acc,
            [block.key]: block
        }), {});
    return <React.Fragment>
        <div className={styles.contactHelpTip}>
            <HelpTip
                msg={tipMsg}
                dispatch={dispatch}
            />
        </div>
        <BlocksByColumn
            key="ODD_ROW"
            canTransparentBackground
            getHeightScale={getHeightScale}
            classNames={styles.contactLayoutFirstRow}
            category={CONTACT_BLOCK}
            leftBlocks={ODD_ROW.left.map(key => blocksMap[key])}
            rightBlocks={ODD_ROW.right.map(key => blocksMap[key])}
            componentsEval={componentsEval}
            styleSheets={styleSheets}
            globalVariables={globalVariables}
            intl={intl}
            dispatch={dispatch}
        />
        <Blocks
            noHeader
            category={CONTACT_BLOCK}
            key="CONTACT_NORMAL_VIEW"
            blocks={CONTACT_NORMAL_VIEW.map(key => blocksMap[key])}
            componentsEval={componentsEval}
            styleSheets={styleSheets}
            globalVariables={globalVariables}
            intl={intl}
            dispatch={dispatch}
            showSeperator
        />
        <BlocksByColumn
            noHeader
            key="COLUMN_VIEW"
            category={CONTACT_BLOCK}
            leftBlocks={COLUMN_VIEW.left.map(key => blocksMap[key])}
            rightBlocks={COLUMN_VIEW.right.map(key => blocksMap[key])}
            componentsEval={componentsEval}
            styleSheets={styleSheets}
            globalVariables={globalVariables}
            intl={intl}
            dispatch={dispatch}
        />
    </React.Fragment>;
});

const mapStateToProps = (appState) => {
    const { sectionsBlocks } = makeEpicStateSelector(sectionsBlocksVAT)(appState);

    return {
        styleSheets: stylesheetAppSel(appState),
        componentsEval: makeEpicStateSelector(componentsEvalValueActionType)(appState),
        globalVariables: makeEpicStateSelector(globalVariablesEpic.valueActionType)(appState),
        blocks: sectionsBlocks.blocks
    };
};

const ContactBlocksView = connect(mapStateToProps)(ContactBlocks);

export default {
    kind: ContactKind,
    shortCut: {
        iconClassName: Icon.contact,
        text: Contact
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.contactTitle {Contact}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} ContactBlocksView={ContactBlocksView} />
    }
};
