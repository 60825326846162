import React, { useState, useMemo, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { Box, Flex } from '../../../view/reflexbox/index';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { UpgradeButton } from '../../../view/common/Button/index';
import ResizeHandle from '../../Panel/view/ResizeHandle';
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import { Msg, injectIntl } from '../../../view/intl/index';
import { openDialog } from '../../App/actionCreators/index';
import { dateToRelativeDays, timestampToDate } from '../../../utils/date';
import { TopBarHeight } from '../../TopBar/constants';
import { introDialogId, upgradeDialogId, namedBackupTitleDialogId, restoreDialogId } from '../dialogs/index';
import { BACKUP_SET_PREVIEW_RECORD, BACKUP_TOGGLE_LEFT_PANEL } from '../actionTypes';
import styles from './Backup.css';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';
import OpenUpgradePlanDialog from '../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog';
import { upgradeDialogOpenedAction } from '../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_BACKUP_RESTORE } from '../../TopBar/view/Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../../TopBar/view/dialogIds';
import { LeftPanelProps } from '../flowTypes';
import getAccessManager from '../../../getAccessManager';

const SavePoints = (
    {
        panelExpanded,
        dimensions: { height },
        timestamp,
        timeline,
        isBackupAndRestoreAllowed,
        dispatch,
        intl,
        subscriptionData,
        hasPages,
        loading
    }: LeftPanelProps
) => {
    const [expandedDates, setExpandedDates] = useState(new Set());
    const [highlightedTime, setHighlightedTime] = useState(null);
    const [visibleOptions, setVisibleOptions] = useState(null);
    const isNamedBackupAllowed = getAccessManager().isNamedBackupRestoreAllowed();

    const toggleDate = useCallback((date) => {
        setExpandedDates((prevDates) => {
            const newDates = new Set(prevDates);
            if (newDates.has(date)) {
                newDates.delete(date);
            } else {
                newDates.add(date);
            }
            return newDates;
        });
    }, []);

    const handleTimeClick = useCallback(
        (time) => {
            setHighlightedTime(time);
            dispatch({
                type: BACKUP_SET_PREVIEW_RECORD,
                payload: time
            });
        },
        [dispatch]
    );

    const scrollHeight = useMemo(() => {
        return (
            height -
            (TopBarHeight +
                parseInt(styles.SavedBackupTopOffset, 10) +
                parseInt(styles.SavedBackupContainerMarginBottom, 10))
        );
    }, [height]);

    const handleOptionsClick = useCallback((time) => {
        setVisibleOptions((prev) => (prev === time ? null : time));
    }, []);

    const premiumPackageNameFromMetadata = useMemo(() => {
        return getPremiumPackageNameFromMetadata(subscriptionData.metadata);
    }, [subscriptionData]);

    const { sortedDates, sortedTimelines } = useMemo(() => {
        let dates: string[] = [];
        let timelines = {};

        R.reverse(timeline).forEach(({ time, published, title }) => {
            const date = dateToRelativeDays(timestampToDate(time, intl), intl);
            const data = { published, time, display: intl.time(time), title };

            if (!dates.includes(date)) {
                dates.push(date);
            }

            if (timelines[date]) {
                timelines[date].push(data);
            } else {
                timelines[date] = [data];
            }
        });

        return { sortedDates: dates, sortedTimelines: timelines };
    }, [timeline, intl]);

    useEffect(() => {
        if (sortedDates.length > 0 && !expandedDates.has(sortedDates[0])) {
            toggleDate(sortedDates[0]);
        }

        if (sortedDates.length > 0 && sortedTimelines[sortedDates[0]].length > 1) {
            handleTimeClick(sortedTimelines[sortedDates[0]][0].time);
        }
    }, [sortedDates, sortedTimelines]);

    useEffect(() => {
        const handleClickOutside = () => {
            setVisibleOptions(null);
        };
        const handleDocumentClick = () => handleClickOutside();
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    if (loading) {
        return (<LoadingIndicator data-testid="loading-indicator" className={styles.leftPanelLoading} />);
    }

    return (
        <div className={cx(styles.panel, { [styles.panelExpaned]: panelExpanded })} data-testid={`panel${panelExpanded ? '-expanded': ''}`}>
            <Box>
                <table className={styles.flexStyle}>
                    <tbody>
                        <tr>
                            <td style={{ width: 19 }}>
                                <HorizontalSpacer x={19} />
                            </td>
                            <td>
                                <Msg k="component.backup.savedBackup">Saved backups</Msg>
                            </td>
                            <td style={{ width: 18 }}>
                                <div
                                    data-testid="help"
                                    className={styles.help}
                                    onClick={() => dispatch(openDialog(introDialogId, { showHideIntro: false }))}
                                />
                            </td>
                            <td style={{ width: 9 }}>
                                <HorizontalSpacer x={9} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Scrollbar height={`${scrollHeight}px`}>
                    {sortedDates.map((date, index) => (
                        <div key={date} className={styles.dateContainer} data-testid="date-container">
                            <div
                                className={styles.date}
                                onClick={() => toggleDate(date)}
                            >
                                <Flex align="center" justify="flex-start">
                                    <div
                                        className={cx(styles.dateIcon, {
                                            [styles.dateIconExpanded]: expandedDates.has(date),
                                            [styles.dateIconCollapsed]: !expandedDates.has(date)
                                        })}
                                        data-testid={`date-row-${expandedDates.has(date) ? 'expanded' : 'collapsed'}`}
                                    />
                                    <HorizontalSpacer x={9} />
                                    <span>{date}</span>
                                </Flex>
                            </div>
                            {expandedDates.has(date) && (
                                <div className={styles.timeContainer}>
                                    {sortedTimelines[date].map(({ published, time, display, title }) => (
                                        <div
                                            key={time}
                                            data-time={time}
                                            className={cx(styles.time, {
                                                [styles.highlighted]: time === highlightedTime,
                                                [styles.timeTitled]: isNamedBackupAllowed && title
                                            })}
                                            onClick={() => handleTimeClick(time)}
                                            data-testid={`${time === highlightedTime ? 'highlighted-' + highlightedTime : ''}`}
                                        >
                                            <div className={styles.timeOptionsContainer}>
                                                <div>
                                                    <span>{display}</span>
                                                    {published && (
                                                        <React.Fragment>
                                                            <span className={styles.published}> - </span>
                                                            <Msg className={styles.published} k="common.published">
                                                                Published
                                                            </Msg>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                {isNamedBackupAllowed && <span
                                                    className={cx(styles.ellipseMenu, {
                                                        [styles.ellipseButtonVisible]: highlightedTime === time
                                                    })}
                                                >
                                                    <button
                                                        className={styles.ellipseButton}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOptionsClick(time);
                                                        }}
                                                        data-testid="ellipse-button"
                                                    />
                                                    {visibleOptions === time && (
                                                        <div className={styles.timestampOptionsContainer}>
                                                            <div
                                                                className={styles.timestampOptions}
                                                                onClick={() => {
                                                                    if (!isBackupAndRestoreAllowed) {
                                                                        dispatch(openDialog(upgradeDialogId));
                                                                    } else if (hasPages) {
                                                                        dispatch(openDialog(restoreDialogId, { timestamp }));
                                                                    }
                                                                }}
                                                            >
                                                                <Msg k="component.backup.option.restore">
                                                                    Restore
                                                                </Msg>
                                                            </div>
                                                            <div
                                                                className={styles.timestampOptions}
                                                                onClick={() => dispatch(
                                                                    openDialog(namedBackupTitleDialogId, {
                                                                        timestamp: time,
                                                                        intl,
                                                                        existingTitle: title
                                                                    })
                                                                )}
                                                            >
                                                                {title ?
                                                                    <Msg k="component.backup.option.editDescription">
                                                                        Edit Description
                                                                    </Msg> :
                                                                    <Msg k="component.backup.option.addDescription">
                                                                        Add Description
                                                                    </Msg>}
                                                            </div>
                                                        </div>
                                                    )}
                                                </span>}
                                            </div>
                                            {title &&
                                                <span data-title={title} className={styles.backupTitle}>{title}</span>}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </Scrollbar>
            </Box>
            {!isBackupAndRestoreAllowed && (
                <div data-testid="upgrade-panel" className={styles.leftPanelUpgrade}>
                    <Box>
                        <VerticalSpacer y={41} />
                        <Flex align="center" justify="center">
                            <div className={styles.backupIcon} />
                        </Flex>
                        <VerticalSpacer y={15} />
                        <Flex align="center" justify="center">
                            <Msg className={styles.centerText} k="component.backup.needBackup">
                                Need backups?
                            </Msg>
                        </Flex>
                        <VerticalSpacer y={6} />
                        <Flex align="center" justify="center">
                            <Msg
                                className={styles.leftPanelUpgradeMsg}
                                k="component.backup.upgradeToPremium"
                                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint("msg: common.premium {Premium}") }}
                            >{`
                                Upgrade to {packageName} and get access to saved versions of your website.
                            `}
                            </Msg>
                        </Flex>
                        <VerticalSpacer y={16} />
                        <Flex align="center" justify="center">
                            <UpgradeButton
                                onClick={() => {
                                    dispatch(OpenUpgradePlanDialog(subscriptionData, "RestorePage:LeftPanelUpgradeButton"));
                                    dispatch(upgradeDialogOpenedAction({
                                        feature: FEATURE_BACKUP_RESTORE,
                                        dialogId: UpgradePlansDialogId
                                    }));
                                }}
                            >
                                <Msg k="common.upgrade">Upgrade</Msg>
                            </UpgradeButton>
                        </Flex>
                        <VerticalSpacer y={10} />
                        <Flex align="center" justify="center">
                            <a
                                href="#"
                                className={cx(styles.leftPanelLearnMore, styles.learnMore)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch(openDialog(upgradeDialogId));
                                    dispatch(upgradeDialogOpenedAction({
                                        feature: FEATURE_BACKUP_RESTORE,
                                        dialogId: upgradeDialogId
                                    }));
                                }}
                            >
                                <Msg k="common.learnMore">Learn more</Msg>
                            </a>
                        </Flex>
                        <VerticalSpacer y={10} />
                    </Box>
                </div>
            )}
            <div data-testid="resize-handle">
                <ResizeHandle
                    onClick={() => dispatch({ type: BACKUP_TOGGLE_LEFT_PANEL })}
                    expanded={panelExpanded}
                />
            </div>
        </div>
    );
};

export default injectIntl(SavePoints);
