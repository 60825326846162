import { AppState } from "../../../../redux/modules/flowTypes";
import valueActionType from "./valueActionType";

const publishStateSelector = (appState: AppState) => {
    return appState.epics[valueActionType].state;
}

const lastPublishedSelector = (appState: AppState) => {
    return appState.epics[valueActionType].state.lastPublished;
};
const showTutorialButtonOnTopBarSelector = (appState: AppState) => {
    const { lastPublished, notLaunched } = appState.epics[valueActionType].state;
    return notLaunched || !lastPublished;
};

export {
    lastPublishedSelector,
    showTutorialButtonOnTopBarSelector,
    publishStateSelector
};
