import { getAppConfig } from "./components/App/epics/appConfig/appConfig";
import { AccessManager } from "../../server/shared/accessManager/AccessManager";
import { SubscriptionTypes } from "./constants/app";

let accessManager: AccessManager = null;

const getAccessManager = () => {
    if (accessManager) return accessManager;
    // By default it always sets to unknown and on load of application or when subscription type changes,
    // we should update it with new subscription type by calling setCurrentSubscriptin method
    const currentSubscription = SubscriptionTypes.UNKNOWN;
    const rawAppConfig = getAppConfig();
    const subscriptionsConfig = rawAppConfig.subscriptions;
    const partnerName = rawAppConfig.partnerName;
    accessManager = new AccessManager(subscriptionsConfig, partnerName);

    accessManager.setCurrentSubscription(currentSubscription);
    return accessManager;
};

export const setAccessManagerToNull = () => {
    accessManager = null;
};

export default getAccessManager;
