import * as R from 'ramda';
import makeEpic from "../../../../epics/makeEpic";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { default as valueActionType } from "./valueActionType";
import * as Actions from "./actionTypes";
import localStorage from '../../../../utils/localStorage';
import {
    DEFAULT_ADDRESS_LOCATION,
    DT_CONCEPT_GENERATION_COUNT,
    DT_GMBKEY, WIZARD_STEP_ID,
    TRIAL_PRE_SELECTED_TEMPLATES,
    GENERIC_CATCH_ALL_NAME,
    CATCH_ALL_CONCEPT_MAP,
    DYNAMIC_PREVIEW_TOASTER_TYPES,
    GPT
} from "../constants";
import getConceptForGmbKey from "./actionCreators/getConceptForGmbKey";
import getGeneratedAIContent from "./actionCreators/getGeneratedAIContent";
import {
    DYNAMIC_TEMPLATE_SHOW_PREVIEW,
    DYNAMIC_TEMPLATE_EXIT_ONBOARDING,
    RETURN_TO_ONBOARDING,
    DYNAMIC_TEMPLATE_SHOW_PREVIEW_INIT_FAILED,
    DYNAMIC_TEMPLATE_SHOW_SELECTED_PAGE_PREVIEW,
} from "../../../TemplateSelector_DEPRECATED/actionTypes";
import { WORKSPACE_READY } from "../../../Workspace/actionTypes";
import { ERROR_TYPES } from "../view/ErrorModels/constants";
import { generalGlobalDataEpic, addressFields } from "../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import { OPEN_AI_TERMS_UPDATE } from '../../../SiteSettings/termsAndConditions/actionTypes';
import { termsAndConditionsGlobalDataVAT } from '../../../SiteSettings/termsAndConditions/termsAndConditionsEpic';
import languagesVAT from '../../../TopBar/epics/languages/valueActionType';
import ownerDataVAT from '../../../App/epics/ownerData/valueActionType';
import { geocode } from '../../../googleMaps/googleMaps';
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from '../../../TemplateSelector_DEPRECATED/view/utils';
import appStatusVat from '../../../App/epics/status/valueActionType';
import { ONBOARDING, READY } from '../../../App/epics/status/appStatusTypes';
import subscriptionDataVAT from '../../../App/epics/subscriptionData/valueActionType';
import { isTrialSubscription } from '../../../App/epics/subscriptionData/utils';
import { SET_TRIAL_USER_EMAIL_ACTION } from '../../../../../demo/modules/actions';
import { fetchSelectedTemplateAction } from './actionCreators/actions';
import getSynonyms from './actionCreators/getSynonyms';
import { isShopOnboardingSelector } from '../../selectorActionTypes';
import {
    GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION,
    GET_TRIAL_GPT_DEMO_DATA_FAILURE_ACTION,
    getTrialGptDemoDataAction
} from '../../../../../demo/modules/gptDemo/actions';
import { getWizardSteps } from '../wizardController';
import { clearGptDataInUrl, constructPayloadForAIOnboarding, getPageIdsToLoad } from './utils';
import { DynamicOnboardingEpicState } from './types';
import { dynamicOnboardingDefaultState as defaultState } from './constants';
import { SEE_ALL_TEMPLATES_CLICKED } from '../../actionTypes';
import { dynamicPreviewToaster } from '../dynamicpreviewToaster';
import getAccessManager from '../../../../getAccessManager';

const makeReducer = (field) => (state: any, { payload }: any) => ({ ...state, [field]: payload });

const shouldAllowMultipageOnboarding = (multipageConceptAvailable, isGptFlow) =>
    getAccessManager().isMultipageOnboardngAllowed() &&
    multipageConceptAvailable &&
    !isGptFlow;

export const dynamicOnboardingEpic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(subscriptionDataVAT),
                receiveOnly(languagesVAT),
                isShopOnboardingSelector,
                optional(SET_TRIAL_USER_EMAIL_ACTION),
                appStatusVat
            ],
            reducer: ({ state, values: [{ subscriptionType }, languages, isShopOnboarding, userInfo, appStatus] }) => {
                const updatedState = { ...state };
                const { current } = languages;

                let multipleActionsToDispatch: any = [];
                if (appStatus === ONBOARDING) {
                    const currURL = new URL(window.location.href);
                    const gptId = currURL.searchParams.get("gptid");

                    if (gptId) {
                        updatedState.source = currURL.searchParams.get("source") || GPT;
                        updatedState.isLoading = true;
                        updatedState.failedAttempt = {
                            action: null,
                            count: 0
                        };
                        multipleActionsToDispatch.push(getTrialGptDemoDataAction(gptId));
                    } else if (isTrialSubscription(subscriptionType) && userInfo?.trialUserEmail) {
                        const selectedTemplateLocalStorage = localStorage.get(TRIAL_PRE_SELECTED_TEMPLATES) || {};
                        const trialEmail = userInfo.trialUserEmail;
                        if (trialEmail && selectedTemplateLocalStorage[trialEmail]) {
                            const params = {
                                accountName: selectedTemplateLocalStorage[trialEmail]
                            };
                            updatedState.isLoading = true;
                            multipleActionsToDispatch.push(fetchSelectedTemplateAction(params));
                        }
                    }

                    const shopDisabled = isOnlineShopCmpsNotAllowedBySubscriptionType();
                    updatedState.shopDisabled = shopDisabled;
                    updatedState.showShopWelcome = !shopDisabled && isShopOnboarding;
                    updatedState.isGptDemo = !!gptId;

                    multipleActionsToDispatch.push(getSynonyms({ locale: current }));
                }

                return {
                    state: updatedState,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY,
            ],
            reducer: ({ state, values: payload }) => {
                if (state.isLoading) {
                    return { state };
                }
                localStorage.set(DT_CONCEPT_GENERATION_COUNT, 1);
                return {
                    state: {
                        ...state,
                        isLoading: true,
                        showCategoryErrorBanner: false,
                        failedAttempt: {
                            action: { type: Actions.DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY, payload },
                            count: 0
                        }
                    },
                    actionToDispatch: getConceptForGmbKey(payload)
                };
            }
        },
        {
            conditions: [
                Actions.GET_CONCEPT_FOR_GMB_KEY_SUCCESS
            ],
            reducer: ({ state, values: [payload] }) => ({
                state: {
                    ...state,
                    conceptMap: {
                        ...state.conceptMap,
                        [state.catchAllConceptKey || state.gmbKey]: payload
                    },
                    lastStepOpened: state.lastStepOpened + 1,
                    concept: payload,
                    isGroup: payload.group,
                    isGroupSet: false,
                    isLoading: false,
                    showCategoryErrorBanner: false,
                    failedAttempt: {
                        action: null,
                        count: 0
                    },
                    isMultipageAllowed: shouldAllowMultipageOnboarding(payload.isMultiPageAvailable, state.isGptDemo),
                },
                actionToDispatch: { type: Actions.TRACK_WIZARD_STEP_CHANGED, payload: WIZARD_STEP_ID.GMB_SELECTION }
            })
        },
        {
            conditions: [
                Actions.GET_CONCEPT_FOR_GMB_KEY_FAILED
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        concept: {},
                        isLoading: false,
                        showCategoryErrorBanner: true,
                        failedAttempt: {
                            action: state.failedAttempt.action,
                            count: state.failedAttempt.count + 1
                        }
                    },
                };
            }
        },
        {
            conditions: [
                Actions.HIDE_CATEGORY_ERROR_BANNER
            ],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    showCategoryErrorBanner: false
                }
            })
        },
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                receiveOnly(languagesVAT),
                Actions.DYNAMIC_TEMPLATE_START_ONBOARDING
            ],
            reducer: ({ state, values: [generalGlobalData, languages] }) => {
                const { contactInfo } = state;

                return {
                    state: {
                        ...state,
                        showWelcomePage: false,
                        lastStepOpened: 0,
                        contactInfo: {
                            ...contactInfo,
                            phone: contactInfo.phone || generalGlobalData.phoneNumber || defaultState.contactInfo.phone,
                            email: contactInfo.email || generalGlobalData.contactEmail || defaultState.contactInfo.email,
                            address: contactInfo.address || generalGlobalData.address || defaultState.contactInfo.address,
                            completeAddress: {
                                ...addressFields.reduce((a, f) => {
                                    let acc = {
                                        ...a,
                                        [f]: contactInfo.completeAddress[f] || generalGlobalData[f] ||
                                            DEFAULT_ADDRESS_LOCATION[f] || defaultState[f]
                                    };
                                    return acc;
                                }, {})
                            }
                        },
                        language: languages.current
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                ownerDataVAT
            ],
            reducer: ({ state, dispatchAsync, values: [generalGlobalData, { ownerData }] }) => {
                if (!ownerData || Object.keys(ownerData).length === 0) {
                    return {
                        state
                    };
                }
                const { contactInfo } = state;
                const _email = contactInfo.email || generalGlobalData.contactEmail || ownerData.email || defaultState.contactInfo.email;
                let _address, isOwnerAddressSet = false;
                if (!contactInfo.address && !generalGlobalData.address && ownerData.address) {
                    _address = ownerData.address;
                    isOwnerAddressSet = true;
                } else {
                    _address = contactInfo.address || generalGlobalData.address || defaultState.contactInfo.address;
                }
                const _completeAddress = {
                    ...addressFields.reduce((a, f) => {
                        let acc = {
                            ...a,
                            [f]: contactInfo.completeAddress[f] || generalGlobalData[f] || ownerData[f] ||
                                DEFAULT_ADDRESS_LOCATION[f] || defaultState[f]
                        };
                        return acc;
                    }, {})
                };
                if (isOwnerAddressSet && _address && !contactInfo?.completeAddress?.addressUrl) {
                    geocode(_address).then(place => {
                        dispatchAsync({
                            type: Actions.DYNAMIC_TEMPLATE_SET_ADDRESS_URL_FROM_ADDRESS,
                            payload: place
                        } as AnyAction);
                    });
                }

                return {
                    state: {
                        ...state,
                        contactInfo: {
                            ...contactInfo,
                            email: _email,
                            address: _address,
                            completeAddress: _completeAddress
                        },
                    }
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_SET_ADDRESS_URL_FROM_ADDRESS
            ],
            reducer: ({ state, values: [address] }) => {
                if (!address) {
                    return {
                        state
                    };
                }
                const { contactInfo } = state;
                return {
                    state: {
                        ...state,
                        contactInfo: {
                            ...contactInfo,
                            completeAddress: address
                        },
                    }
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY
            ],
            reducer: ({ state, values: [payload] }) => ({
                state: {
                    ...state,
                    concept: payload,
                    isGroup: payload.group,
                    isGroupSet: false,
                    isMultipageAllowed: shouldAllowMultipageOnboarding(payload.isMultiPageAvailable, state.isGptDemo),
                }
            })
        },
        {
            conditions: [
                receiveOnly(termsAndConditionsGlobalDataVAT),
                Actions.DYNAMIC_TEMPLATE_GET_AI_CONTENT,
            ],
            reducer: ({ state, values: [termsAndConditions, { pagesToGenerate = [], isSilentLoad = false, isFirstLoad = false }] }) => {
                const failedAttemptActionType = Actions.DYNAMIC_TEMPLATE_GET_AI_CONTENT,
                    failedAttemptCount = (state.failedAttempt.action && state.failedAttempt.action.type ===
                        Actions.DYNAMIC_TEMPLATE_GET_AI_CONTENT) ? (state.failedAttempt.count || 0) : 0;

                let multipleActionsToDispatch: any[] = [];

                if (!state.isSilentLoading) {
                    multipleActionsToDispatch = [getGeneratedAIContent(
                        constructPayloadForAIOnboarding(state, { pagesToGenerate })
                    )];
                }

                if (!termsAndConditions?.openAI) {
                    multipleActionsToDispatch.push({
                        type: OPEN_AI_TERMS_UPDATE,
                        payload: state.agreeToTerms
                    });
                }

                let isFirstLoadRequestPending = isFirstLoad && state.isSilentLoading

                return {
                    state: {
                        ...state,
                        pagesToGenerate: isFirstLoadRequestPending ? state.pagesToGenerate : pagesToGenerate,
                        isLoading: !isSilentLoad,
                        isSilentLoading: isFirstLoadRequestPending ? state.isSilentLoad : isSilentLoad,
                        isFirstLoad: isFirstLoad && !state.isSilentLoading,
                        isFirstLoadRequestPending: isFirstLoad && state.isSilentLoading,
                        failedAttempt: {
                            action: { type: failedAttemptActionType, payload: { pagesToGenerate } },
                            count: failedAttemptCount
                        },
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION
            ],
            reducer: ({ state, values: [{ data: { input, output } }] }) => {
                const {
                    gmbKey,
                    websiteTitle,
                    optionSet,
                    group,
                    aboutKeywords,
                    submitCount,
                    concept: {
                        name
                    }
                } = input;
                const isGroupSet = !input.concept.hasOwnProperty('group');

                let catchAllConcept = {};
                if (CATCH_ALL_CONCEPT_MAP[name]) {
                    catchAllConcept = {
                        catchAllConceptKey: CATCH_ALL_CONCEPT_MAP[name],
                        isNewGmbKey: true,
                    };
                }

                const updatedState = {
                    ...state,
                    ...input,
                    ...catchAllConcept,
                    businessName: websiteTitle,
                    purpose: optionSet,
                    keywords: aboutKeywords,
                    isGroup: group,
                    isLoading: false,
                    failedAttempt: {
                        action: null,
                        count: 0
                    },
                    showWelcomePage: false,
                    showShopWelcome: false,
                    isGroupSet,
                    agreeToTerms: true,
                    showGenericConcept: name === GENERIC_CATCH_ALL_NAME
                };
                const steps = getWizardSteps(updatedState);
                updatedState.lastStepOpened = steps.length ? steps.length - 1 : 0;
                localStorage.set(DT_CONCEPT_GENERATION_COUNT, submitCount || 1);
                localStorage.set(DT_GMBKEY, gmbKey);

                return {
                    state: updatedState,
                    multipleActionsToDispatch: [{
                        type: DYNAMIC_TEMPLATE_EXIT_ONBOARDING
                    }, {
                        type: DYNAMIC_TEMPLATE_SHOW_PREVIEW,
                        payload: {
                            previewData: output.previewData,
                            designData: output.designData,
                            backButtonActionType: RETURN_TO_ONBOARDING
                        },
                    }, {
                        type: OPEN_AI_TERMS_UPDATE,
                        payload: true
                    }]
                };
            }
        },
        {
            conditions: [
                Actions.GET_GENERATED_AI_CONTENT_SUCCESS
            ],
            reducer: ({ state, values: [{ previewData, designData }] }) => {
                const submitCount = localStorage.get(DT_CONCEPT_GENERATION_COUNT) || 1;
                localStorage.set(DT_CONCEPT_GENERATION_COUNT, parseInt(submitCount + 1, 10));

                let multipleActionsToDispatch: any = [];

                let isFirstLoadRequestPending = state.isFirstLoadRequestPending;
                if (state.pagesToGenerate.length && !state.isFirstLoad) {
                    multipleActionsToDispatch = [{
                        type: DYNAMIC_TEMPLATE_SHOW_SELECTED_PAGE_PREVIEW,
                        payload: {
                            previewData,
                            pageId: !state.isSilentLoading ? state.pagesToGenerate[0] : null,
                        }
                    }];
                    if (state.isFirstLoadRequestPending) {
                        multipleActionsToDispatch.push({
                            type: Actions.DYNAMIC_TEMPLATE_GET_AI_CONTENT, payload: { isFirstLoad: true }
                        })
                        isFirstLoadRequestPending = false;
                    }
                } else {
                    multipleActionsToDispatch = [{
                        type: DYNAMIC_TEMPLATE_EXIT_ONBOARDING
                    }, {
                        type: DYNAMIC_TEMPLATE_SHOW_PREVIEW,
                        payload: {
                            previewData,
                            designData,
                            backButtonActionType: RETURN_TO_ONBOARDING,
                        }
                    }];

                    const pageIdsToload = getPageIdsToLoad(previewData);
                    if (pageIdsToload.length) {
                        multipleActionsToDispatch.push({
                            type: Actions.DYNAMIC_TEMPLATE_GET_AI_CONTENT, payload: { pagesToGenerate: pageIdsToload, isSilentLoad: true }
                        })
                    }
                }

                return {
                    state: {
                        ...state,
                        isLoading: false,
                        isSilentLoading: false,
                        isFirstLoadRequestPending,
                        failedAttempt: {
                            action: null,
                            count: 0
                        }
                    },
                    multipleActionsToDispatch
                };
            }
        },
        ...[
            GET_TRIAL_GPT_DEMO_DATA_FAILURE_ACTION,
            Actions.GET_GENERATED_AI_CONTENT_FAILED
        ].map(actionType => ({
            conditions: [actionType, receiveOnly(appStatusVat)],
            reducer: ({ state, values: [data, appStatus] }) => {
                let multipleActionsToDispatch, errorType: string | null = null;
                const source = state.isGptDemo ? state.source : null;

                if (data.status === 451) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_INAPPROPRIATE_LANGUAGE_ERROR;
                } else if (data.status === 429) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_LIMIT_ERROR;
                } else if (data.status === 503) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_HIGH_TRAFFIC_ERROR;
                } else {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_CONNECTION_ERROR;
                }
                multipleActionsToDispatch = [{
                    type: DYNAMIC_TEMPLATE_SHOW_PREVIEW_INIT_FAILED
                },
                { type: Actions.TRACK_EVENT_NEXT_GEN_API_FAILURE, payload: { errorType, source } }];

                if (appStatus === READY && !state.isSilentLoading) {
                    errorType = DYNAMIC_PREVIEW_TOASTER_TYPES.MULTIPAGE_LOAD_ERROR;
                    multipleActionsToDispatch.push(dynamicPreviewToaster(errorType))
                }

                return {
                    state: {
                        ...state,
                        isLoading: false,
                        isSilentLoading: false,
                        failedAttempt: {
                            action: state.failedAttempt.action,
                            count: state.failedAttempt.count + 1
                        },
                        errorType
                    },
                    multipleActionsToDispatch
                };
            }
        })),
        {
            conditions: [
                Actions.WIZARD_STEP_CHANGED
            ],
            reducer: ({ state, values: [step] }) => {
                return {
                    state: {
                        ...state,
                        lastStepOpened: step,
                        showCategoryErrorBanner: false
                    }
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_RESET_ERROR_TYPE
            ],
            reducer: ({ state }) => {
                clearGptDataInUrl();

                return {
                    state: {
                        ...state,
                        errorType: null,
                        source: null,
                        isGptDemo: false,
                    }
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA
            ],
            reducer: ({ state, values: [payload] }) => {
                const { path, value } = payload;
                const pathList = path.split('.');

                return {
                    state: {
                        ...state,
                        animationData: R.assocPath(pathList, value, state.animationData)
                    }
                };
            }
        },
        {
            conditions: [
                Actions.GET_SELECTED_TEMPLATE_SUCCESS
            ],
            reducer: ({ state, values: [payload] }) => {
                return {
                    state: {
                        ...state,
                        selectedTemplate: payload,
                        isLoading: false
                    }
                };
            }
        },
        {
            conditions: [
                Actions.GET_SELECTED_TEMPLATE_FAILED
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        isLoading: false
                    }
                };
            }
        },
        {
            conditions: [
                Actions.GET_SYNONYMS_FOR_LANGUAGE_SUCCESS
            ],
            reducer: ({ state, values: [payload] }) => {
                return {
                    state: {
                        ...state,
                        synonyms: payload
                    }
                };
            }
        },
        ...[
            SEE_ALL_TEMPLATES_CLICKED,
            RETURN_TO_ONBOARDING
        ].map(actionType => ({
            conditions: [
                actionType
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        errorType: null,
                        isLoading: false,
                    }
                };
            }
        })),
    ],
    handleActions: {
        [Actions.DYNAMIC_TEMPLATE_SHOW_WELCOME_PAGE]: (state) => ({ ...state, showWelcomePage: true }),
        [Actions.DYNAMIC_TEMPLATE_AGREE_TERMS]: makeReducer('agreeToTerms'),
        [Actions.DYNAMIC_TEMPLATE_BUSINESS_NAME_CHANGED]: makeReducer('businessName'),
        [Actions.DYNAMIC_TEMPLATE_CALL_ALL_CONCEPT_KEY_CHANGED]: makeReducer('catchAllConceptKey'),
        [Actions.DYNAMIC_TEMPLATE_KEYWORDS_CHANGED]: makeReducer('keywords'),
        [Actions.DYNAMIC_TEMPLATE_LANGUAGE_CHANGED]: makeReducer('language'),
        [Actions.DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO]: makeReducer('contactInfo'),
        [Actions.DYNAMIC_TEMPLATE_UPDATE_WEBSITE_PURPOSE]: makeReducer('purpose'),
        [Actions.DYNAMIC_TEMPLATE_UPDATE_ISGROUP]: (state, { payload }) => ({
            ...state,
            isGroup: payload,
            isGroupSet: true
        }),
        [Actions.DYNAMIC_TEMPLATE_SET_UNKNOWN_GMB_KEY]: (state: DynamicOnboardingEpicState, { payload }) =>
            ({ ...state, concept: {}, isLoading: false, isNewGmbKey: true, gmbKey: payload }),
        [WORKSPACE_READY]: (state) => ({ ...defaultState, conceptMap: state.conceptMap }),
        [Actions.DYNAMIC_TEMPLATE_GMB_KEY_UPDATED]: (state, { payload }) => ({
            ...state, purpose: [], concept: {}, isNewGmbKey: false, catchAllConceptKey: '', gmbKey: payload, showCategoryErrorBanner: false
        })
    }
});
