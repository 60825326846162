import CodeKind from '../oneweb/Code/kind';
import ContactFormKind from '../oneweb/ContactForm/kind';
import DocumentKind from '../oneweb/Document/kind';
import TableKind from '../oneweb/Table/kind';
import WebShopKind from '../oneweb/WebShop/kind';
import FeaturedProductsKind from '../oneweb/FeaturedProducts/kind';
import { InstagramGalleryKind } from "../oneweb/InstagramGallery/kind";
import { FacebookFeedGalleryKind } from "../oneweb/FacebookFeedGallery/kind";
import { FacebookChatKind } from "../oneweb/FacebookChat/kind";
import { GOOGLE_REVIEWS } from '../oneweb/componentKinds';

const getComponentDisabledId = componentKind => `${componentKind}Disabled`;

export const
    TemplateComponent = 'TemplateComponent',
    OutofTemplateArea = 'OutofTemplateArea',
    ShiftbarClick = 'ShiftbarClick',
    CodeDisabled = getComponentDisabledId(CodeKind),
    ContactFormDisabled = getComponentDisabledId(ContactFormKind),
    DocumentDisabled = getComponentDisabledId(DocumentKind),
    TableDisabled = getComponentDisabledId(TableKind),
    WebShopDisabled = getComponentDisabledId(WebShopKind),
    HostnetWebShopDisabled = `Hostnet${getComponentDisabledId(WebShopKind)}`,
    FindTips = 'FindTips',
    FindVideoTip = 'FindVideoTip',
    FindVideoTipOnSkip = 'FindVideoTipOnSkip',
    ComponentResize = 'ComponentResize',
    SectionResize = 'SectionResize',
    MobileDownDisabled = 'MobileDownDisabled',
    MobileEditorMenu = 'MobileEditorMenu',
    MobileEditorPageName = 'MobileEditorPageName',
    MobileEditorReorderTip = 'MobileEditorReorderTip',
    MobileEditorGroupTip = 'MobileEditorGroupTip',
    BrowserZoomWarn = 'BrowserZoomWarn',
    StripTitleErrorTip = 'StripTitleErrorTip',
    ExtendedPanelTooltip = 'ExtendedPanelTooltip',
    ReplaceButtonTooltip = 'ReplaceButtonTooltip',
    HeaderSectionMCTATooltip = 'HeaderSectionMCTATooltip',
    FooterSectionMCTATooltip = 'FooterSectionMCTATooltip',
    HeaderSectionTooltip = 'HeaderSectionTooltip',
    FooterSectionTooltip = 'FooterSectionTooltip',
    SectionShortcutInfoTooltip = 'ShortcutInfoTooltip',
    ModernLayoutInfoTooltip = 'ModernLayoutInfoTooltip',
    SectionLinkPageDeleted = 'SectionLinkPageDeleted',
    SectionLinkOutdated = 'SectionLinkOutdated',
    SectionFirstIntroToaster = 'SectionFirstIntroToaster',
    AIWritingAssistantTooltip = 'AIWritingAssistantTooltip',
    SpellcheckTooltip = 'SpellcheckTooltip',
    PhoneTip = "PhoneTip",
    EmailTip = "EmailTip",
    AddressTip = "AddressTip",
    FeaturedProductsDisbaled = getComponentDisabledId(FeaturedProductsKind),
    InstagramGalleryDisabled = getComponentDisabledId(InstagramGalleryKind),
    FacebookFeedGalleryDisabled = getComponentDisabledId(FacebookFeedGalleryKind),
    FacebookFeedForceDisabled = `${FacebookFeedGalleryKind}ForceDisabled`,
    GoogleReviewsForceDisabled = `${GOOGLE_REVIEWS}ForceDisabled`,
    GenericFeatureDisabled = `GenericFeatureDisabled`,
    FacebookChatDisabled = getComponentDisabledId(FacebookChatKind),
    FacebookChatForceDisabled = `${FacebookChatKind}ForceDisabled`,
    InstagramForceDisabled = 'InstagramForceDisabled',
    addYourShopToolTipSeen = "addYourShopToolTipSeen",
    SingleProductComponentTip = "SingleProductComponentTip",
    NewComponentBookingsToolip = "NewComponentBookingsToolip",
    BookingsPublishButtonTooltip = "BookingsPublishButtonTooltip";
