import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PANEL_ITEM_CLICK, PANEL_ITEM_LEFT_MOUSE_DOWN } from "../../../../Panel/actionTypes";
import BookingsKind from "../../kind";
import { Msg } from "../../../../../view/intl";
import {
    CLOSE_STICKY_TOOLTIP,
    SHOW_TOOLTIP_FOR_NEW_COMPONENT
} from "../../../../Tooltip/stickyTooltip/actionTypes";
import { selectWorkspaceStatus } from "../../../../Workspace/epics/status/selectors";
import { READY } from "../../../../Workspace/epics/status/types";
import { NewComponentBookingsToolip } from "../../../../Tooltip/ids";
import styles from "./BookingsTooltip.css";
import { savePreferencesAction } from "../../../../Preferences/actions";
import { preferencesSelector } from "../../../../Preferences/selectors";
import Icons from "../../../../../view/Icons";

export const BookingsTooltipView = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles.tooltipContainer}>
            <Icons.BOOKINGS_INSERTER className={styles.image} data-testid="bookings-image" />

            <div className={styles.header} data-testid="tooltip-header">
                <Msg k="tooltip.newComponentBookings.header">New tool: Bookings!</Msg>
            </div>
            <div className={styles.description} data-testid="tooltip-description">
                <Msg k="tooltip.newComponentBookings.text.new">
                    Let customers book your services right through your website - easy and
                    convenient.
                </Msg>
            </div>

            <div className={styles.buttonsContainer}>
                <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={() => {
                        dispatch(
                            savePreferencesAction({
                                hideBookingsEditorTooltip: true
                            })
                        );
                        dispatch({
                            type: CLOSE_STICKY_TOOLTIP
                        });
                    }}
                    data-testid="try-later-button"
                >
                    <Msg k="tooltip.newComponentBookings.actionText">I’ll try it later</Msg>
                </button>
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        dispatch({
                            type: PANEL_ITEM_LEFT_MOUSE_DOWN,
                            payload: { componentKind: BookingsKind }
                        });
                        dispatch({
                            type: PANEL_ITEM_CLICK,
                            payload: { componentKind: BookingsKind }
                        });
                        dispatch(
                            savePreferencesAction({
                                hideBookingsEditorTooltip: true
                            })
                        );
                    }}
                    data-testid="try-now-button"
                >
                    <Msg k="common.tryItOut">Try it out</Msg>
                </button>
            </div>
        </div>
    );
};

let tooltipSeen = false;

export const useBookingsTooltipEffect = (
    bookingsTabRef: React.MutableRefObject<HTMLDivElement | null>,
    itemKind: string
) => {
    const dispatch = useDispatch();
    const workspaceStatus = useSelector(selectWorkspaceStatus);
    const { whatsNewPopupSeenId, hideBookingsEditorTooltip } = useSelector(preferencesSelector);

    useEffect(() => {
        // only show the tooltip if the user has seen the whats new popup in the previous session
        // value 50 is taken from wbtgen/notifications/whatsNewList.ts for bookings feature
        // the tooltip must be rendered after the workspace is ready, otherwise it will be closed
        if (
            !tooltipSeen &&
            itemKind === BookingsKind &&
            bookingsTabRef.current &&
            whatsNewPopupSeenId &&
            whatsNewPopupSeenId >= 50 &&
            !hideBookingsEditorTooltip &&
            workspaceStatus === READY
        ) {
            const inserterTabRect = bookingsTabRef.current.getBoundingClientRect();
            const { top, width, height, left } = inserterTabRect;
            tooltipSeen = true;

            dispatch({
                type: SHOW_TOOLTIP_FOR_NEW_COMPONENT,
                payload: {
                    // position is where the tooltip arrow should point to
                    // at the right and in the middle of the inserter tab
                    position: { x: left + width, y: top + height / 2 },
                    elementDimensions: { top, width, height, left },
                    viewId: NewComponentBookingsToolip,
                    backgroundColor: "var(--colorLinkWater)",
                    closeIconProps: {
                        size: "13px",
                        positionOffset: "20px"
                    }
                }
            });
        }
    }, [dispatch, workspaceStatus, bookingsTabRef]);
};
