import { getAppConfig } from "../components/App/epics/appConfig/appConfig";
import { getDAL } from '../../dal/index';
import AppConfig from "./AppConfig";
import getAccessManager from "../getAccessManager";

type DemoData = {
    isDemo: boolean,
    email?: string
}
const disableFBComponents = (demoData?: DemoData) => {
    const appConfig = AppConfig(getAppConfig()),
        isFacebookEnabled = getAccessManager().isFacebookComponentEnabled();
    if (isFacebookEnabled) {
        const fbWhitelistedDomain = appConfig.getOptional(`oneWeb.facebook.FBWhitelistedDomains`, []),
            domainName = (demoData && demoData.isDemo) ? demoData.email : getDAL().getDomain();
        // Case when whitelisted domains are available then feature should work
        // only with whitelisted domains
        if (fbWhitelistedDomain &&
            fbWhitelistedDomain.length > 0 &&
            !fbWhitelistedDomain.includes(domainName)) {
            return true;
        } else {
        // If feature is enabled and no whitelisted domains then feature should work
        // for all.
            return false;
        }
    }
    return true;
};

export default disableFBComponents;
