import { dialogManagerStateFromAppStateSelector, openedDialogIdsSelector } from "../../DialogManager/epic/selectors"
import BookingsManagementDialogId from "./BookingsManagementDialog/dialogId";

const isBookingsDialogOpenSelector = (appState) => {
    const openDialogIds = openedDialogIdsSelector(dialogManagerStateFromAppStateSelector(appState));
    return openDialogIds.includes(BookingsManagementDialogId);
}

export {
    isBookingsDialogOpenSelector
};
