import React from "react";
import cx from "classnames";
import { Button } from '@mui/material';
import { useDispatch } from "react-redux";

import { Msg, useIntl } from "../../../view/intl/index";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import Scrollbar from '../../../view/common/Scrollbar';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';
import { isNormalSubscription } from "../../App/epics/subscriptionData/utils";
import { upgrade } from "../../ComponentTierManager/actionCreators";
import { SubscriptionType } from '../../../../../server/shared/accessManager/constants';
import { TemplateCategory } from '../../oneweb/Template/constants';
import { TEMPLATE_FILTER_TEXT, TEMPLATE_FILTER } from "../constants";
import * as Actions from '../actionTypes';
import styles from './TemplateSelector.css';
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "./utils";

export const TemplateSelectorMenu = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const
        {
            selectorListState,
            subscriptionType,
            subscriptionData,
        } = props,
        {
            filter = TEMPLATE_FILTER.all,
        } = selectorListState;

    const filterTemplate = (filter: string) => dispatch({ type: Actions.FILTER_TEMPLATE_LIST, payload: filter });
    const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionData.metadata);

    let categories = Object.keys(TEMPLATE_FILTER_TEXT);
    const filterOutOnlineShop = isOnlineShopCmpsNotAllowedBySubscriptionType();

    if (filterOutOnlineShop) {
        categories = categories.filter(c => c !== TemplateCategory.ONLINE_STORE);
    }

    return (
        <div className={styles.templateSelectorMenu}>
            <Scrollbar height="100%" theme={{ trackVertical: styles.trackVertical }}>
                <div className={styles.templateSelectorMenuWrapper}>
                    <ul>
                        {
                            categories.map((_filter, i) => (
                                <li key={i} onClick={() => filterTemplate(_filter)}>
                                    <span className={cx({ [styles.selected]: _filter === filter })}>
                                        {intl.msgJoint(TEMPLATE_FILTER_TEXT[_filter])}
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                    {
                        isNormalSubscription(subscriptionType) &&
                        <span
                            className={styles.premiumBtn}
                            onClick={() => dispatch(upgrade({
                                upgradeType: SubscriptionType.PREMIUM,
                                upgradeEventLabel: 'TemplateSelector_DEPRECATED:UpgradeBtn'
                            }))}
                        >
                            <Msg
                                k="common.learnMoreAboutPremium"
                                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                            >{`
                            Learn more about {packageName}
                        `}</Msg>
                        </span>
                    }
                </div>
                <HorizontalSpacer x={10} />
            </Scrollbar>
            <div className={styles.templateSelectorMenuFooter}>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.footerBackbtn}
                    onClick={() => dispatch({ type: Actions.TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED })}
                >
                    <span className={styles.arrowleftIcon} />
                    {intl.msgJoint("msg: common.back {Back}")}
                </Button>
            </div>
        </div>
    );
};
