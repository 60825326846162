import React from 'react';
import * as pageIds from '../ids';
import pagesTitles from '../titles';

import Page from '../../../../PropertiesPanel/view/PropertiesPage';

import type { FontPageComponentDependencies } from '../../flowTypes';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import FontFamily from '../../../../presentational/FontFamily';
import addGoogleFontView from '../../../../PropertiesPanel/view/AddGoogleFont/index';
import BookingsKind from '../../kind';
import * as actionTypes from "../../actionTypes";
import * as selectors from "../../../../Workspace/epics/stylesheets/selectors";
import ColorProperty from '../../../../presentational/ColorProperty';
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';

const
    id = pageIds.FONT,
    title = pagesTitles[id],
    view = (props: FontPageComponentDependencies) => {
        const {
            siteFonts,
            selectedComponent,
            dispatch,
            navigateToPage,
            stylesheets,
            themeSettingsData: { autoColorMode }
        } = props;
        const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets);
        const themeColorsData = selectors.getThemeColorsFromStylesheet(stylesheets);

        return (
            <Page>
                <VerticalSpacer />
                <FontFamily
                    selected={selectedComponent.fontFamily || textNormalStyle.font}
                    onChangeAction={actionTypes.BOOKINGS_FONT_FAMILY_CHANGED}
                    siteFonts={siteFonts}
                    onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: BookingsKind })}
                    isPropertiesPanel
                />
                <VerticalSpacer />
                {
                    autoColorMode ? [
                        [
                            <ColorProperty
                                key="text-color"
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.titleFontColorTheme}
                                defaultThemeColor="blackColor"
                                label="msg: common.fontColorTitle {Font color title}"
                                onChangeAction={actionTypes.BOOKINGS_TITLE_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                key="accent-color"
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.fontColorTheme}
                                defaultThemeColor="blackColor"
                                label="msg: common.fontColor {Font color}"
                                onChangeAction={actionTypes.BOOKINGS_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                        ]
                    ] : [
                        <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: common.fontColorTitle {Font color title}"
                            color={selectedComponent.titleFontColor}
                            onChangeAction={actionTypes.BOOKINGS_TITLE_COLOR_CHANGED}
                            onRemoveAction=""
                            dispatch={dispatch}
                        />,
                        <VerticalSpacer />,
                        <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: common.fontColor {Font color}"
                            color={selectedComponent.fontColor}
                            onChangeAction={actionTypes.BOOKINGS_COLOR_CHANGED}
                            onRemoveAction=""
                            dispatch={dispatch}
                        />
                    ]
                }
            </Page>
        )
    }

export { id, title, view };
