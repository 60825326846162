import { DASHBOARD_BOOKINGS_ROUTE } from "../../../../../server/shared/routes";
import { isDashboardBookingsRoute } from "../../../../../server/lib/handlers/routeUtils";
import { getAppConfig } from "../../../../../wbtgen/src/components/App/epics/appConfig/appConfig";
import { getLoginDomain } from "../../../../../wbtgen/utils/loginData";
import AppConfig from "../../../../../wbtgen/src/utils/AppConfig";
import { getDAL } from "../../../../../wbtgen/dal";

import type { AppState } from "../../common/types";
import type { BookingsMessageTypes } from "./types";
import { BOOKINGS_IFRAME_ID, BOOKINGS_PAGES_ROUTES } from "./constants";
import {
    bookingsIntroDialogSeenSelector,
    hideBookingsTooltipSelector,
    isNewToDashboard,
    selectSiteData
} from "../../common/selectors";
import { isWsbDemo } from "../../../../../wbtgen/src/debug/isWsbDemo";

export const isBookingsEnabledFromConfig = () => {
    // bookings is not enabled for trial
    if (isWsbDemo()) {
        return false;
    }

    const appConfig = AppConfig(getAppConfig());
    const enabled = appConfig.getOptional("oneWeb.apps.bookings.enabled");
    const whitelist = appConfig.getOptional("oneWeb.apps.bookings.whitelistedDomains");

    if (Array.isArray(whitelist) && whitelist.length > 0) {
        return enabled && whitelist.includes(getLoginDomain());
    }

    return enabled;
};

export const getBookingsIframeToken = () => {
    return getDAL().getAppViewToken('bookings');
};

export const getBookingsUrlWithToken = (route: string, token: string) => {
    const appConfig = AppConfig(getAppConfig());
    const appUrl = appConfig.get("oneWeb.apps.bookings.appUrl");
    const url = new URL(appUrl + route);
    url.searchParams.append('bs-req-app', 'wsb');
    url.searchParams.append('bs-shrt-tkn', token);
    return url.toString();
};

export const getBookingsRouteFromLocation = () => {
    if (isDashboardBookingsRoute(location.pathname)) {
        const path = (
            location.pathname.substring(DASHBOARD_BOOKINGS_ROUTE.length) ||
            BOOKINGS_PAGES_ROUTES.HOME
        );

        return path + location.search;
    }
    return null;
};

export const mapBookingsRouteToLocationRoute = (route: string): string => {
    return DASHBOARD_BOOKINGS_ROUTE + route;
};

export const bookingsIframePostMessage = (message: { type: BookingsMessageTypes; data?: any; }) => {
    const bookingsIframe = document.getElementById(BOOKINGS_IFRAME_ID) as Nullable<HTMLIFrameElement>;

    if (bookingsIframe && bookingsIframe.contentWindow) {
        const urlOrigin = new URL(bookingsIframe.src).origin;
        bookingsIframe.contentWindow.postMessage(message, urlOrigin);
    }
};

export const showBookingsTooltipFactory = () => {
    let _showBookingsTooltip: boolean | null = null;

    const showBookingsTooltip = (appState: AppState) => {
        // preferences take priority if set
        if (hideBookingsTooltipSelector(appState)) {
            return false;
        }

        // this value is memoized for the lifetime of the app
        if (_showBookingsTooltip === null) {
            _showBookingsTooltip = Boolean(
                !isNewToDashboard(appState) &&
                bookingsIntroDialogSeenSelector(appState)
            );
        }

        return _showBookingsTooltip;
    };

    return showBookingsTooltip;
};

export const isDashboardBookingsRouteLoaded = () => {
    return Boolean(
        isBookingsEnabledFromConfig() &&
        getBookingsRouteFromLocation()
    );
};

export const hasBookingsPage = (appState: AppState) => {
    return selectSiteData(appState)?.getAllBookingPages?.()?.length > 0;
};
