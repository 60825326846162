import { EventTrackingIds, EventTrackingProps } from './constants';
import { INITIATE_WSB_ONBOARDING } from '../Onboarding/actionTypes';
import type { AppState } from '../../redux/modules/flowTypes';
import { lastPublishedSelector } from '../TopBar/epics/checkPublishStatus/selectors';
import { PUBLISH } from '../App/actionTypes';
import { DynamicTemplateTrackingEvents } from '../Onboarding/Dynamic/trackEvents';
import { AITextMixpanelEvents } from '../oneweb/Text/epics/AITextEpic/events';
import { UpgradeEvents } from '../TopBar/view/Upgrade/tracking/events';
import { PreviewTrackingEvents } from '../Preview/eventsTracker';
import { WorkspaceTrackingEvents } from '../Workspace/workspaceTrackingEvents';
import { EventsRegistryType } from './types';
import { TextMixpanelEvents } from '../oneweb/Text/events';
import { VideoTutorialEvents } from '../TopBar/view/TutorialVideoDialog/events';
import { EditTextMixpanelEvents } from '../oneweb/Text/epics/EditAITextEpic/events';
import { ContactFormMixpanelEvents } from '../oneweb/ContactForm/events';
import { siteDataStateAppSel } from '../App/epics/siteData/selectors';
import DataSite from '../../../dal/model/DataSite';

export const EventsRegistry: EventsRegistryType = {
    ...DynamicTemplateTrackingEvents,
    ...AITextMixpanelEvents,
    ...EditTextMixpanelEvents,
    ...TextMixpanelEvents,
    ...VideoTutorialEvents,
    ...UpgradeEvents,
    ...PreviewTrackingEvents,
    ...WorkspaceTrackingEvents,
    ...ContactFormMixpanelEvents,
    [INITIATE_WSB_ONBOARDING]: {
        eventName: EventTrackingIds.INITIATE_WSB_ONBOARDING,
        propsHandler: () => ({
            [EventTrackingProps.oldOnboarding]: true,
            [EventTrackingProps.version]: 'v1'
        })
    },
};
