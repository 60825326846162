/* @flow */

const { WSBSubToPartnerConfigType, SubscriptionType } = require('./constants');

// Below list of components should match the list of components in the subscriptions inside
// the common.cjson
const ConfigComponentList = {
    VIDEO_FILE: "VIDEO_FILE",
    HD_IMAGES: "HD_IMAGES",
    ONLINE_SHOP: "ONLINE_SHOP",
    GOOGLE_REVIEWS: "GOOGLE_REVIEWS",
    WIDGETS_BOOKINGS: "WIDGETS_BOOKINGS",
    GOOGLE_MY_BUSINESS: "GOOGLE_MY_BUSINESS",
    GENERIC_WIDGETS: "GENERIC_WIDGETS",
    MULTIPAGE_ONBOARDING: "MULTIPAGE_ONBOARDING",
    GENERATE_SCREENSHOT: "GENERATE_SCREENSHOT",
    CONTACTS: "CONTACTS",
    BACKUP_RESTORE: "BACKUP_RESTORE",
    DASHBOARD: "DASHBOARD",
    SOCIAL_WIDGETS: "SOCIAL_WIDGETS",
    SITE_SETTINGS: "SITE_SETTINGS",
    FILE_CHOOSER: "FILE_CHOOSER",
    TOP_BAR: "TOP_BAR"
};

const matchSubscriptionType = (currentSubscription) => (
    currentSubscription === SubscriptionType.DEMO ? SubscriptionType.TRIAL : currentSubscription
);
const getFeaturesConfig = (subscriptionConfig, partnerName, currentSubscription) => {
    const wsbSubToPartnerConfigSubMap = WSBSubToPartnerConfigType[partnerName];
    if (!wsbSubToPartnerConfigSubMap) {
        throw new Error("Invalid subscription feature management config");
    }
    const partnerSubsConfig = wsbSubToPartnerConfigSubMap[currentSubscription];
    // TODO: Skip logs for test environment
    // console.log(`FEATUREMANAGER: currentSubscription: ${currentSubscription} | partnerSubsConfig: ${partnerSubsConfig}`);
    return subscriptionConfig[partnerSubsConfig];
};

class AccessManager {
    /*:: #_partnerName: string; */
    /*:: #_subscriptionConfig: Object; */
    /*:: #_currentSubscription: string; */
    /*:: #_featuresConfig: Object; */

    constructor(subscriptionConfig/*: Object */ = {}, partnerName/*: string */ = '') {
        this./*::#*/_partnerName = partnerName;
        this./*::#*/_subscriptionConfig = subscriptionConfig;
        this./*::#*/_currentSubscription = SubscriptionType.UNKNOWN;
        this./*::#*/_featuresConfig = {};
    }

    getCurrentSubscription() {
        return this./*::#*/_currentSubscription;
    }

    setCurrentSubscription(subs/*: Object */) {
        this./*::#*/_currentSubscription = matchSubscriptionType(subs);
        this./*::#*/_featuresConfig = getFeaturesConfig(
            this./*::#*/_subscriptionConfig, this./*::#*/_partnerName, this./*::#*/_currentSubscription
        );
    }

    isHdImagesAllowed() {
        return this./*::#*/_featuresConfig[ConfigComponentList.HD_IMAGES];
    }

    isVideoFileCmpAllowed() {
        return this./*::#*/_featuresConfig[ConfigComponentList.VIDEO_FILE];
    }

    isOnlineShopCmpsAllowed() {
        /* This includes all type of components related to webshop */
        const onlineShop = this./*::#*/_featuresConfig[ConfigComponentList.ONLINE_SHOP];
        return onlineShop && onlineShop.enabled;
    }

    isGoogleReviewsAllowed() {
        const googleReviews = this./*::#*/_featuresConfig[ConfigComponentList.GOOGLE_REVIEWS];
        return googleReviews && googleReviews.enabled;
    }

    isBoookingsWidgetAllowed() {
        const bookingsWidget = this./*::#*/_featuresConfig[ConfigComponentList.WIDGETS_BOOKINGS];
        return bookingsWidget && bookingsWidget.enabled;
    }

    isGoogleMyBusinessAllowed() {
        const googleMyBusiness = this./*::#*/_featuresConfig[ConfigComponentList.GOOGLE_MY_BUSINESS];
        return googleMyBusiness && googleMyBusiness.enabled;
    }

    isGenericWidgetAllowed() {
        const genericWidgets = this./*::#*/_featuresConfig[ConfigComponentList.GENERIC_WIDGETS];
        return genericWidgets && genericWidgets.enabled;
    }

    isMultipageOnboardngAllowed() {
        const multipageOnboarding = this./*::#*/_featuresConfig[ConfigComponentList.MULTIPAGE_ONBOARDING];
        return multipageOnboarding && multipageOnboarding.enabled;
    }

    shouldGenerateScreenshot() {
        const generateScreenshot = this./*::#*/_featuresConfig[ConfigComponentList.GENERATE_SCREENSHOT];
        return generateScreenshot && generateScreenshot.enabled;
    }

    isContactsShown() {
        const displayContacts = this./*::#*/_featuresConfig[ConfigComponentList.CONTACTS];
        return displayContacts && displayContacts.enabled;
    }

    isNamedBackupRestoreAllowed() {
        const backupRestore = this./*::#*/_featuresConfig[ConfigComponentList.BACKUP_RESTORE];
        return backupRestore && backupRestore.namedBackup && backupRestore.namedBackup.enabled;
    }

    isPublishButtonEnabled() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.publishButton && topBar.publishButton.enabled;
    }

    isSubscriptionTypeLabelShown() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.subscriptionTypeLabel && topBar.subscriptionTypeLabel.show;
    }

    isUpgradeButtonShown() {
        /* This includes all type of components related to webshop */
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.upgradeButton && topBar.upgradeButton.show;
    }

    isTopBarCPLinkShown() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.goToCPLink && topBar.goToCPLink.show;
    }

    isShutterstockShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.shutterstock &&
            fileChooser.shutterstock.show
        );
    }

    isUnsplashShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.unsplash &&
            fileChooser.unsplash.show
        );
    }

    isFileChooserShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return fileChooser && fileChooser.goToFileManager && fileChooser.goToFileManager.show;
    }

    isSeoTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.seoTab && siteSettings.seoTab.show;
    }

    isSocialShareTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.socialShareTab && siteSettings.socialShareTab.show;
    }

    isTrackingTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.trackingTab && siteSettings.trackingTab.show;
    }

    isSecurityTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.securityTab && siteSettings.securityTab.show;
    }

    isBusinessListingTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.businessListingTab && siteSettings.businessListingTab.show;
    }

    isSocialWidgetsShown() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.show;
    }

    isInstagramComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.enabled
    }

    isInstagramComponentForceDisabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.forceDisabled;
    }

    isFacebookComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.facebookComponent && socialWidgets.facebookComponent.enabled;
    }

    isGoogleReviewsComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.enabled;
    }

    isGoogleReviewsComponentShown() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.show;
    }

    isDashboardEnabled() {
        const dashboard = this./*::#*/_featuresConfig[ConfigComponentList.DASHBOARD];
        return dashboard && dashboard.enabled;
    }
}

module.exports = {
    AccessManager,
};
