import React from "react";
import styles from "./ExpandedPanelImage.css";
import { FmTabId } from "../../../FileManager/dialog/constants";
import ImageKind from "../kind";
import { imageComponentConfig } from "../index";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import type { ExtendedPanelContentProps } from "../../../Panel/view/inserter";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import EPSeparator from "../../../Panel/view/EPSeparator";
import inserterStyles from '../../../Panel/view/Inserter.css';
import HelpTip from "../../../Panel/view/HelpTip";
import { SVG_KIND } from '../../Svg/kind';
import svgComponentConfig from "../../Svg/index";
import AppConfig from "../../../../utils/AppConfig";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig";
import getAccessManager from "../../../../getAccessManager";

const appConfig = AppConfig(getAppConfig()),
    isShutterstockEnabled = getAccessManager().isShutterstockShown(),
    isUnsplashEnabled = getAccessManager().isUnsplashShown();

const imageOptions = [{
    heading: 'msg: extendedPanel.myUploads {My uploads}',
    helpTip: 'msg: extendedPanel.myUploads.helpTip {Upload and add your own images or vector graphics.}',
    items: [{
        title: 'msg: fm.dialog.tab.myImages.title {My images}',
        subTitle: 'msg: leftPanel.image.myImages.subTitle {Add your own images}',
        imageClass: styles.myImages,
        kind: ImageKind,
        isEnabled: true,
        configDialogProps: { ...imageComponentConfig.configurationDialog, activeTabId: FmTabId.MY_IMAGES, hideMyVideosTab: true },
    }, {
        title: 'msg: extendedPanel.myVectorGraphics.title {My vector graphics}',
        subTitle: 'msg: extendedPanel.myVectorGraphics.subTitle {Add your own vector graphics}',
        imageClass: styles.myVectorGraphics,
        kind: SVG_KIND,
        isEnabled: true,
        configDialogProps: { ...svgComponentConfig.configurationDialog, activeTabId: FmTabId.MY_IMAGES, hideMyVideosTab: true },
    }],
}, {
    heading: 'msg: extendedPanel.imageCollections {Image collections}',
    helpTip: 'msg: extendedPanel.imageCollections.helpTip {Browse the collection of free images or purchase high quality images at a discounted price.}', // eslint-disable-line max-len
    items: [{
        title: 'msg: fm.dialog.tab.freeUnsplash.title {Free from Unsplash}',
        subTitle: `msg: leftPanel.image.freeImages.subTitle {Choose from 1000's of images}`,
        imageClass: styles.freeUnsplashImages,
        kind: ImageKind,
        isEnabled: isUnsplashEnabled,
        configDialogProps: { ...imageComponentConfig.configurationDialog, activeTabId: FmTabId.UNSPLASH },
    },
    {
        title: 'msg: fm.dialog.tab.freeOnecom.title {Free from one.com}',
        subTitle: `msg: leftPanel.image.freeImages.subTitle {Choose from 1000's of images}`,
        imageClass: styles.freeImages,
        kind: ImageKind,
        isEnabled: isShutterstockEnabled,
        configDialogProps: { ...imageComponentConfig.configurationDialog, activeTabId: FmTabId.FREE_ONECOM_IMAGES },
    }, {
        title: 'msg: fm.dialog.tab.paidShutterstock.title {Shutterstock}',
        subTitle: 'msg: leftPanel.image.paidImages.subTitle {Buy high quality images}',
        imageClass: styles.paidImages,
        kind: ImageKind,
        isEnabled: isShutterstockEnabled,
        configDialogProps: { ...imageComponentConfig.configurationDialog, activeTabId: FmTabId.PAID_SHUTTERSTOCK },
    }],
}];

const ImageTemplates = (props: ExtendedPanelContentProps): Array<AnyReactElement> => {
    const { intl, dispatch } = props;

    return (
        imageOptions.map(({ items, heading, helpTip }, index) => {
            return (
                <div key={index}>
                    {index !== 0 && <React.Fragment><VerticalSpacer y={22} /> <EPSeparator /></React.Fragment>}
                    <VerticalSpacer y={23} />
                    <div className={inserterStyles.sectionsExtendedPanelTitleWrapper}>
                        <h3 className={inserterStyles.subTitle}>{intl.msgJoint(heading)}</h3>
                        <HelpTip
                            msg={helpTip}
                            dispatch={dispatch}
                        />
                    </div>
                    <VerticalSpacer y={12} />
                    <div>
                        {
                            items.map(({ title, subTitle, kind, configDialogProps, imageClass, isEnabled }, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        { index !== 0 && <VerticalSpacer y={7} />}
                                        {
                                            isEnabled && <DroppableCmpShortcut
                                                kind={kind}
                                                payload={{ dialogProps: configDialogProps }}
                                                {...props}
                                                className={styles.item}
                                                isOverlayExtended
                                            >
                                                <div className={styles.imageContainer}>
                                                    <div className={`${styles.image} ${imageClass}`}> </div>
                                                </div>
                                                <div className={styles.textContainer}>
                                                    <span className={styles.title}>{intl.msgJoint(title)}</span>
                                                    <span className={styles.subTitle}>{intl.msgJoint(subTitle)}</span>
                                                </div>
                                            </DroppableCmpShortcut>
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                </div>
            );
        })
    );
};

export { ImageTemplates };

