import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from "@mui/material";
import cx from "classnames";

import { useIntl, Msg } from "../../../../../../../wbtgen/src/view/intl";
import { UpgradeDialogPlanTypeMap } from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/UpgradePlansDialog/constants";
import { SubscriptionType } from "../../../../../../../server/shared/accessManager/constants";
import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import {
    isBasicSubscription,
    isEcommerceSubscription
} from "../../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import {
    getPlanPricePerMonth, getPlanPricePerMonthWithoutCurrency,
} from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/utils";
import { FEATURE_PLANS_OVERVIEW } from '../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/tracking/contants';

import { getOfferMsgKey, getKeyFeatures } from "./utils";
import { upgradeButtonClickedAction } from "../actions";
import { UPGRADE_APPROVE_DIALOG_ID } from "../UpgradeApproveDialog/UpgradeApproveDialog";
import { openDialogAction, closeDialogAction } from "../../../Dialog/actions";
import { upgradeAction } from "../../../Subscription/actions";
import type { PlanSelectionViewProps } from "../types";
import { styles } from "./styles";
import { getCapitalizedCaseName } from '../utils';
import UpgradeText from "../../../../../../../wbtgen/src/components/UpgradeText";

const PLANS_DESCRIPTION_MAPPING = {
    [SubscriptionType.BASIC]: "msg: upgrade.plan.basic.description {Perfect for a simple online presence}",
    [SubscriptionType.NORMAL]: "msg: upgrade.plan.starter.description {Add more pages and broaden your site’s reach}",
    [SubscriptionType.PREMIUM]: "msg: upgrade.plan.premium.description {Expand with unlimited pages and get more space for your website}",
    [SubscriptionType.ECOMMERCE]: "msg: upgrade.plan.ecommerce.description {Tailored to give you everything you need for your business}"
};

let isAnyInfoAvailable = false;

const renderPlanPriceInfo = (upgradeType, chargesForUpgrade, isFreeUpgradeAvailable, freeMonths, intl) => {
    const
        classes = styles(),
        isBasicUser = isBasicSubscription(upgradeType),
        pricePerMonth = getPlanPricePerMonthWithoutCurrency(chargesForUpgrade),
        { currencyCode } = chargesForUpgrade;

    const monthText = intl.msgJoint("msg: upgrade.plan.month {/month}");
    const pricePerMonthText = `${currencyCode} ${(isBasicUser || isFreeUpgradeAvailable) ? 0 : pricePerMonth},- `;
    const renewsAt = intl.msgJoint([`msg: upgrade.plan.renewal.info_new {Renews at {pricePerMonth}{currencyCode} /month}`, { currencyCode, pricePerMonth }]);

    let info = '';

    if (isFreeUpgradeAvailable && freeMonths) info = intl.msgJoint([`msg: upgrade.plan.free.monthsWithoutAsterisk {Free for the next {freeMonths} months}`, { freeMonths }]);
    if (isBasicUser) info = intl.msgJoint("msg: upgrade.plan.included {Included in hosting plan}");

    return (
        <div>
            <div
                className={classes.priceInfo}
            >
                {pricePerMonthText}
                <span className={classes.perMonth}>{monthText}</span>
            </div>
            {info && (
                <React.Fragment>
                    <VerticalSpacer y={16} />
                    <span className={classes.freeInfo}>
                        {info}
                    </span>
                </React.Fragment>
            )}
            {isBasicUser ? null : (
                <React.Fragment>
                    <VerticalSpacer y={(isAnyInfoAvailable && !info) ? 55 : 16} />
                    <div className={classes.renewInfo}>
                        {renewsAt}. {intl.msgJoint("msg: common.billed.yearly {Billed yearly}")}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export const onClickHandler = ({ dispatch, isFreeUpgradeAvailable, upgradeType, freeMonths, chargesForUpgrade }) => {
    dispatch(closeDialogAction());
    dispatch(upgradeButtonClickedAction({
        feature: FEATURE_PLANS_OVERVIEW,
        freeUpgrade: isFreeUpgradeAvailable,
        upgradeType
    }));

    if (isFreeUpgradeAvailable) {
        dispatch(openDialogAction(UPGRADE_APPROVE_DIALOG_ID, {
            freeMonths,
            upgradeType,
            pricePerMonth: getPlanPricePerMonth(chargesForUpgrade),
        }));
    } else {
        dispatch(upgradeAction({ upgradeType, isFreeUpgrade: isFreeUpgradeAvailable }));
    }
};

export const PlanSelectionView = ({
    upgradeType,
    chargesForUpgrade,
    freeMonths,
    isFreeUpgradeAvailable,
    isCurrentPlan = true,
    classes,
    divRefs,
    titleRefs,
    descriptionRefs,
    index,
    hideRecommended
}: PlanSelectionViewProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isBasicUser = isBasicSubscription(upgradeType);
    const isAnyInfo = (isFreeUpgradeAvailable && freeMonths && !isBasicUser) ? true : false;
    const _classes = styles();

    if (isAnyInfo) isAnyInfoAvailable = true;

    const isEcommercePlan = isEcommerceSubscription(upgradeType);

    let buttonText = (<Msg k="common.select">Select</Msg>);
    if (isCurrentPlan) {
        buttonText = (<Msg k="common.current">Current</Msg>);
    } else if (isEcommercePlan) {
        buttonText = (<UpgradeText />);
    }

    return (
        <div className={cx(_classes.container, classes?.container)}>
            {
                (isEcommercePlan && !hideRecommended) ?
                    <div className={_classes.recommended}>
                        {intl.msgJoint("msg: common.recommended {Recommended}")}
                    </div>
                    : <VerticalSpacer y={hideRecommended ? 0 : 27} />
            }
            <Grid className={cx(_classes.root, classes?.root)}>
                <div
                    className={_classes.topSection}
                    ref={el => {
                        divRefs.current[index] = el as HTMLDivElement;
                    }}
                >
                    <div
                        className={_classes.upgradePlanHeader}
                        ref={el => {
                            titleRefs.current[index] = el as HTMLDivElement;
                        }}
                    >
                        {getCapitalizedCaseName(intl.msgJoint(UpgradeDialogPlanTypeMap[upgradeType]))}
                    </div>
                    <VerticalSpacer y={8} />
                    <div
                        className={_classes.upgradePlanDescription}
                        ref={el => {
                            descriptionRefs.current[index] = el as HTMLDivElement;
                        }}
                    >
                        {intl.msgJoint(PLANS_DESCRIPTION_MAPPING[upgradeType])}
                    </div>
                    <VerticalSpacer y={16} />
                    {renderPlanPriceInfo(upgradeType, chargesForUpgrade, isFreeUpgradeAvailable, freeMonths, intl)}
                </div>
                <VerticalSpacer y={10} />
                <Button
                    variant={(isCurrentPlan || isEcommercePlan) ? "contained" : "outlined"}
                    color="primary"
                    className={_classes.selectPlanButton}
                    disabled={isCurrentPlan}
                    data-testid={upgradeType + "_select_btn_top"}
                    onClick={() => onClickHandler({ dispatch, isFreeUpgradeAvailable, upgradeType, freeMonths, chargesForUpgrade })}
                >
                    {buttonText}
                </Button>
                <VerticalSpacer y={24} />
                <div className={cx(_classes.feature, _classes.keyFeatures)}>{intl.msgJoint("msg: common.keyFeatures {Key features}")}</div>
                {
                    getKeyFeatures(upgradeType).map((offerType, index) => {
                        const isBg = !(index % 2);
                        return (
                            <div key={index} className={_classes.planOffersListContainer}>
                                <div className={cx(_classes.feature, { [_classes.featureWithBg]: isBg })}>
                                    {intl.msgJoint(getOfferMsgKey(offerType))}
                                </div>
                            </div>
                        );
                    })
                }
            </Grid>
        </div>
    );
};
