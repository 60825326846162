export const
    DeletePageAndSubPagesDialogId = 'DeletePageAndSubPagesDialog',
    DeleteHomePageAndSubPagesDialogId = 'DeleteHomePageAndSubPagesDialog',
    DeleteLinkPageDialogId = 'DeleteLinkPageDialog',
    DeleteSectionLinkDialogId = 'DeleteSectionLinkDialogId',
    PageInfoDialogId = 'PageInfoDialog',
    LinkPageInfoDialogId = 'LinkPageInfoDialog',
    SectionLinkInfoDialogId = 'SectionLinkInfoDialog',
    DeleteBookingsPageDialogId = "DeleteBookingsPageDialogId",
    DeleteActivatedBookingsPageDialogId = "DeleteActivatedBookingsPageDialogId";

